<template>
  <div class="container" id="container">
    <div style="position: relative;display: flex;" @click="subjectShow = false">
      <echarts
        class="echarts-init"
        @student_list="student_list"
        ref="myecharts"
        myCharts="myCharts"
        @click.native="mychartenlarge(true)"
      ></echarts>
      <div class="explain_Answer1 col-md-1">
        <div class="aboust_bott20">
          <!-- <p class="explain_right buttonBase"  @click="mychartenlarge(false)">
						增加柱状图测试
					</p> -->
          <!-- <p class="explain_right" v-if="!teachexplainShow" @click.stop="subjectShow=!subjectShow">
						{{$store.state.teachlanguagedata.QuestionCard}}
					</p> -->
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow"
            @click="InclassCorrect()"
            :class="[InclassCorrectshow ? 'selectmintopicindexs' : '']"
          >
            <span v-if="InclassCorrectshow">{{
              $store.state.teachlanguagedata.end
            }}</span>
            <span v-else>{{ $store.state.teachlanguagedata.start }}</span>
          </p>
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow && teach_val_length > 1"
            @click="kong('jian')"
          >
            {{ $store.state.teachlanguagedata.previous }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="InclassCorrectshow && teachplanIdshow && teach_val_length > 1"
          >
            {{ $store.state.teachlanguagedata.Selects }}{{ teach_valindex + 1
            }}{{ $store.state.teachlanguagedata.Empty }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow && teach_val_length > 1"
            @click="kong('jia')"
          >
            {{ $store.state.teachlanguagedata.next }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="!teachexplainShow"
            @click="gettiqing()"
          >
            {{ $store.state.teachlanguagedata.answer }}
          </p>
          <p class="explain_right buttonBase" @click="writingInterface()">
            {{ $store.state.teachlanguagedata.writing }}
          </p>
          <!-- <p class="explain_right" @click="explain_return()">{{$store.state.teachlanguagedata.return}} </p> -->
          <el-collapse v-model="activeNames">
            <el-collapse-item
              :title="$store.state.teachlanguagedata.more"
              name="1"
            >
              <p class="explain_right buttonBase" v-if="!teachexplainShow">
                <span @click="startRecording" v-if="!videoStart">{{
                  $store.state.teachlanguagedata.StartRecording
                }}</span>
                <span
                  @click="
                    stopRecording(true);
                    parentVideoStart = false;
                  "
                  v-if="videoStart"
                  id="btn-stop-recording"
                  >{{ $store.state.teachlanguagedata.EndRecording }}</span
                >
              </p>
              <div
                style="z-index: 10;"
                class="explain_right buttonBase"
                @click="canves_show = true"
                :class="[canves_show ? 'selectmintopicindexs' : '']"
                v-if="!teachexplainShow"
              >
                <span @click="canves_show = true">{{
                  $store.state.teachlanguagedata.comments
                }}</span>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div
        class="container_con col-md-10 container_con1 height85vh"
        v-if="teachexplainShow"
        @mousedown="mousedown"
        id="zhangsan"
      >
        <div>
          <p>{{ $store.state.teachlanguagedata.Remainingtime }}</p>
          <p>{{ teachtime }}</p>
        </div>
      </div>
      <div
        class="container_con col-md-10 height85vh"
        v-else
        @mousedown="mousedown"
        id="zhangsan"
      >
        <div
          class="container_con_html"
          id="container_con_html"
          @mousewheel.prevent="rollImg"
        >
          <div style="display: flex;justify-content: space-between;">
            <div
              v-html="content"
              style="text-align: left;"
              id="container_con_htmltigan"
              class="container_con_htmltigan content-img-width"
              ref="imgDiv"
              @touchstart="touchStart"
              @touchmove="touchmove"
            ></div>
          </div>
        </div>
        <!-- <div id="ques" class="explain_ques" v-if="explain_quesshow">
					<div v-for="(item,index) in que" :key="index" style="display: flex;margin-right: 20px;"
						:id="'explain_quesshow'+index">
						<el-button @click="myBoxLeft(index);handleclicks();handleclick(item.id,item,index);"
							type="button" class="btn btn-default font_size"
							:class="[selectmintopicindex==index?'selectmintopic':'']">
							{{item.questionOutVo.featureTypeName}}:{{item.questionOutVo.index?item.questionOutVo.index:index+1}};<span
								v-if="item.right_rate">{{$store.state.teachlanguagedata.Correct2}}:{{item.right_rate | Rounding}};{{$store.state.teachlanguagedata.answer2}}:{{item.submit_rate | Rounding}}</span>
						</el-button>
						<el-button style="margin-top: 20px;" class="font_size1"
							@click.stop="myBoxLeft(index);handleclicks();handleclick(items.id,items,index,indexs);"
							v-for="(items,indexs) in item.childrenList" :key="indexs"
							:class="[childrenListindex==indexs?'selectmintopic':'']">
							<span>
								{{items.questionOutVo.featureTypeName}}{{item.questionOutVo.index?item.questionOutVo.index:index+1}}:{{items.questionOutVo.index}};<span
									v-if="items.right_rate">{{$store.state.teachlanguagedata.Correct2}}：{{items.right_rate | Rounding}};{{$store.state.teachlanguagedata.answer2}}：{{items.submit_rate | Rounding}}</span>
							</span>
						</el-button>
					</div>
				</div> -->
        <div class="positionAbsolute right0 bottombai10" v-if="!subjectShow">
          <img
            style="width: 40px;"
            src="../../assets/img/Stow.webp"
            @click.stop="
              subjectShow = !subjectShow;
              subjectShowPosition = 'right';
            "
            alt=""
          />
        </div>
        <div
          class="positionAbsolute left0 bottombai10"
          style="transform: rotate(180deg);"
          v-if="!subjectShow"
        >
          <img
            style="width: 40px;"
            src="../../assets/img/Stow.webp"
            @click.stop="
              subjectShow = !subjectShow;
              subjectShowPosition = 'left';
            "
            alt=""
          />
        </div>
      </div>
      <div class="explain_Answer2  col-md-1">
        <div class="aboust_bott20">
          <!-- <p class="explain_right" v-if="!teachexplainShow" @click.stop="subjectShow=!subjectShow">
						{{$store.state.teachlanguagedata.QuestionCard}}
					</p> -->
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow"
            @click="InclassCorrect()"
            :class="[InclassCorrectshow ? 'selectmintopicindexs' : '']"
          >
            <span v-if="InclassCorrectshow">{{
              $store.state.teachlanguagedata.end
            }}</span>
            <span v-else>{{ $store.state.teachlanguagedata.start }}</span>
          </p>
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow && teach_val_length > 1"
            @click="kong('jian')"
          >
            {{ $store.state.teachlanguagedata.previous }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="InclassCorrectshow && teachplanIdshow && teach_val_length > 1"
          >
            {{ $store.state.teachlanguagedata.Selects }}{{ teach_valindex + 1
            }}{{ $store.state.teachlanguagedata.Empty }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="teachplanIdshow && teach_val_length > 1"
            @click="kong('jia')"
          >
            {{ $store.state.teachlanguagedata.next }}
          </p>
          <p
            class="explain_right buttonBase"
            v-if="!teachexplainShow"
            @click="gettiqing()"
          >
            {{ $store.state.teachlanguagedata.answers }}
          </p>
          <p class="explain_right buttonBase" @click="writingInterface()">
            {{ $store.state.teachlanguagedata.writing }}
          </p>
          <!-- <p class="explain_right" @click="explain_return()">{{$store.state.teachlanguagedata.return}} </p> -->
          <el-collapse v-model="activeNames">
            <el-collapse-item
              :title="$store.state.teachlanguagedata.more"
              name="1"
            >
              <p class="explain_right buttonBase" v-if="!teachexplainShow">
                <span @click="startRecording" v-if="!videoStart">{{
                  $store.state.teachlanguagedata.StartRecording
                }}</span>
                <span
                  @click="
                    stopRecording(true);
                    parentVideoStart = false;
                  "
                  v-if="videoStart"
                  id="btn-stop-recording"
                  >{{ $store.state.teachlanguagedata.EndRecording }}</span
                >
              </p>
              <div
                style="z-index: 10;"
                class="explain_right buttonBase"
                @click="canves_show = true"
                :class="[canves_show ? 'selectmintopicindexs' : '']"
                v-if="!teachexplainShow"
              >
                <span @click="canves_show = true">{{
                  $store.state.teachlanguagedata.comments
                }}</span>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div
      id="题情"
      v-eldragdialog
      class="explain_answer_con"
      :style="{ display: answer != 0 ? '' : 'none' }"
      @click="answer = 0"
    >
      <div @click.stop="answer = 1" style="zoom: 1.5;">
        <div class="explain_answer_con1">
          <div>
            <div class="explain_answer_con1_Base">题情</div>
          </div>

          <div class="topicSituationBase">
            <p>应答人数:{{ result.total_student_number }}</p>
            <p>实答人数:{{ result.submit_student_number }}</p>
            <p
              v-if="
                result.questionOutVo.featureCode != '1400001' &&
                  result.questionOutVo.featureCode != '1300001'
              "
            >
              答题率:{{ result.submit_rate }}%
            </p>
            <p
              v-if="
                result.questionOutVo.featureCode != '1400001' &&
                  result.questionOutVo.featureCode != '1300001'
              "
            >
              正确率:{{ result.right_rate }}%
            </p>
          </div>
          <!-- 正确学生 -->
          <div
            v-if="
              result.questionOutVo.featureCode != '1400001' &&
                result.questionOutVo.featureCode != '1300001' &&
                result.right_students &&
                result.right_students.length > 0
            "
          >
            <div class="explain_answer_con1_Base">
              {{ $store.state.teachlanguagedata.correctstudent }}
            </div>
            <a
              v-for="(item, index) in result.right_students"
              :key="index"
              v-on:click="handleclick1(item)"
              >{{ item.name ? item.name : item }}
            </a>
          </div>
          <!-- 错误学生 -->
          <div v-if="result.wrong_students && result.wrong_students.length > 0">
            <div class="explain_answer_con1_Base">
              {{ $store.state.teachlanguagedata.incorrectstudent }}
            </div>
            <a
              v-for="(item, index) in result.wrong_students"
              :key="index"
              v-on:click="handleclick1(item)"
              >{{ item.name ? item.name : item }}
            </a>
          </div>
          <!-- 未批改学生 -->
          <!-- <div v-if="result.uncorrect_students&&result.uncorrect_students.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.Uncorrectedstudents}}</div>
						<a v-for="(item,index) in result.uncorrect_students" :key="index"
							v-on:click="handleclick1(item)">{{item.name?item.name:item}} </a>
					</div> -->
          <!-- 未答学生 -->
          <!-- <div v-if="result.unjoin_users&&result.unjoin_users.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.unansweredstudents}}</div>
						<a v-for="(item,index) in result.unjoin_users" :key="index+1"
							@click="handleclick1(item)">{{item.name?item.name:item}} </a>
					</div> -->
        </div>
        <div
          class="explain_answer_con2"
          @mousewheel.prevent="rollImg1"
          ref="imgDiv1"
          @touchstart="touchStart"
          @touchmove="touchmove"
          v-if="
            result.questionOutVo.featureCode != '1400001' &&
              result.questionOutVo.featureCode != '1300001'
          "
        >
          <div style="display: flex; padding-top: 25px;">
            <div
              class="explain_answer_con1_Base explain_answer_con1_Base_title"
            >
              {{ $store.state.teachlanguagedata.answers }} :
            </div>
            <div style="display:flex;flex-wrap: wrap; flex-direction: column;">
              <span
                id="questionanswerList"
                class="topicSituationBase"
                style="margin-right:20px;display:flex;"
                v-for="(item, index) in result.questionOutVo.answerList"
                :key="index"
                >{{ index + 1 }}.<span v-html="item.value"></span
              ></span>
            </div>
          </div>
          <div style="display: flex;">
            <div
              class="explain_answer_con1_Base explain_answer_con1_Base_title"
            >
              {{ $store.state.teachlanguagedata.analysis }} :
            </div>
            <span
              id="questionanalysis"
              class="topicSituationBase"
              v-html="result.questionOutVo.analysis"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <Board v-if="canves_show" v-on:close="close"></Board>
    <!-- 学生笔记弹框 -->
    <el-dialog
      :visible.sync="writingInterfaceshow"
      width="90%"
      class="my-info-dialog"
    >
      <div
        v-if="
          teachplanIdPlankind == '作业' &&
            teachplanIdType == '课中' &&
            teachanswertype == '作业本'
        "
      >
        <dotcans
          v-if="writingInterfaceshow"
          ref="dotimg"
          style="height:80vh;"
        ></dotcans>
      </div>
      <div v-else>
        <dotcan
          :teachexplainShow="teachexplainShow"
          :intercept="teachexplainShow ? false : intercept"
          :pageNoShow="teachexplainShow ? false : true"
          v-if="writingInterfaceshow"
          ref="dotimg"
          style="height:95vh;"
        >
        </dotcan>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="usrDotshow"
      width="90%"
      height="90vh"
      class="my-info-dialog"
    >
      <div v-if="usrDotshow">
        <Correct ref="Correct" v-if="usrDotshow"></Correct>
      </div>
    </el-dialog>
    <screen-recording
      ref="screen-recording"
      @streamStop="streamStop"
      :fileName="fileName"
      :paramsPlanId="$route.params.plan_id"
    ></screen-recording>
    <div
      v-if="subjectShow"
      class="queclass"
      :class="[subjectShowPosition == 'left' ? 'left10' : 'right10']"
    >
      <span v-for="(item, index) in que" :key="index">
        <span
          @click="
            myBoxLeft(index);
            handleclicks();
            handleclick(item.id, item, index);
          "
          type="button"
          class="selectmintopictext"
          style="position: relative;padding: 0 10px;"
          :class="[
            selectmintopicindex == index
              ? 'selectmintopicindexs'
              : 'selectmintopicindex',
            item.childrenList &&
            item.childrenList[0].right_rate &&
            item.childrenList[0].right_rate > greaterThan[0].value
              ? 'green'
              : item.childrenList &&
                item.childrenList[0].right_rate > greaterThan[1].value
              ? 'yellow'
              : 'red',
            item.right_rate && item.right_rate > greaterThan[0].value
              ? 'green'
              : item.right_rate > greaterThan[1].value
              ? 'yellow'
              : 'red',
          ]"
        >
          <el-popover placement="bottom" trigger="hover">
            <!-- 这里是题目正确率 -->
            <span>
              {{
                item.questionOutVo.index ? item.questionOutVo.index : index + 1
              }}:{{ item.questionOutVo.featureTypeName }}
              <span
                v-if="
                  item.right_rate &&
                    item.questionOutVo.featureCode != '1400001' &&
                    item.questionOutVo.featureCode != '1300001'
                "
                >{{ $store.state.teachlanguagedata.Correct2 }}:{{
                  item.right_rate
                }}%</span
              >
            </span>
            <div slot="reference">
              {{
                item.questionOutVo.index ? item.questionOutVo.index : index + 1
              }}:{{ item.questionOutVo.featureTypeName | TruncateString }}
            </div>
          </el-popover>
        </span>
        <span
          @click.stop="
            myBoxLeft(index);
            handleclicks();
            handleclick(items.id, items, index, indexs);
          "
          v-for="(items, indexs) in item.childrenList"
          :key="indexs"
          style="position: relative;padding: 0 10px;"
          :class="[
            childrenListindex == indexs && index == selectmintopicindex
              ? 'selectmintopicindexs'
              : 'selectmintopicindex',
            items.right_rate && items.right_rate > greaterThan[0].value
              ? 'green '
              : items.right_rate > greaterThan[1].value
              ? 'yellow '
              : 'red',
          ]"
        >
          <el-popover placement="bottom" trigger="hover">
            <span>
              {{ items.questionOutVo.featureTypeName }}:{{
                items.questionOutVo.index
                  ? items.questionOutVo.index
                  : indexs + 1
              }}(小)
              <span
                v-if="
                  items.right_rate &&
                    items.questionOutVo.featureCode != '1400001' &&
                    items.questionOutVo.featureCode != '1300001'
                "
                >{{ $store.state.teachlanguagedata.Correct2 }}:{{
                  items.right_rate
                }}%</span
              >
            </span>
            <div slot="reference">
              {{
                items.questionOutVo.index
                  ? items.questionOutVo.index
                  : indexs + 1
              }}(小)
            </div>
          </el-popover>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import {
  getItems,
  realTimeAnswer,
  correction_in_class,
  gotUserJoinAllStation,
  save,
} from '../../api/Explain.js';
import {
  queryUserListByClassId,
  levelSettingQueryList,
} from '../../api/base.js';
import Board from '../../components/CanvasBoard/index.vue';
import echarts from '../../components/Class/echart';
import dotcan from '../../components/dot.vue';
import dotcans from '../../components/dots.vue';
import Correct from '../../components/OneStudents/index.vue';
import { WebSocketFunc } from '../../utils/src_utils_socket.js';
import RecordRTC from 'recordrtc';
import screenRecording from '../../components/screen-recording';
import baseJs from '../../utils/base/base.js';
import baseJson from '../../assets/json/base.json';
export default {
  name: 'Person',
  components: {
    Board,
    echarts,
    dotcan,
    dotcans,
    Correct,
    screenRecording,
  },
  computed: {
    changeMemberTab() {
      return this.$store.state.teachclass;
    },
  },
  watch: {
    originScale(val) {
      console.log(val, 'valjfjfjfj');
      if (this.answer == 1) {
        this.$refs.imgDiv1.style.zoom = parseInt(val * 100) + '%';
      } else {
        this.$refs.imgDiv.style.zoom = parseInt(val * 100) + '%';
      }
    },
    videoStart(val) {
      this.$store.state.videoStart = val;
    },
    InclassCorrectshow(val) {
      localStorage.setItem('teachInclassCorrectshow', val);
    },
    changeMemberTab(val) {
      this.class_id = val.id;
      this.initial();
    },
    writingInterfaceshow(vla) {
      if (vla) {
        localStorage.setItem('teachansDotShow', JSON.stringify(true));
      } else {
        localStorage.removeItem('teachansDotShow');
        this.$refs.dotimg.tableClear();
      }
    },
  },
  data() {
    return {
      aa: '',
      levelSetting: {
        currPage: 1,
        pageSize: 10,
      },
      originScale: 1,
      subjectShowPosition: null,
      intercept: true, //是否展示一道题
      videoStart: false, //默认开始录屏
      screenRecordingShow: false, //默认开始录屏
      parentVideoStart: false, //切换题目开始录屏
      activeNames: null,
      greaterThan: JSON.parse(JSON.stringify(baseJson.greaterThan)),
      subjectShow: true,
      userId: null,
      fileName: Date.parse(new Date()) + '录屏',
      video: null,
      isCreate: false,
      teachplanIdshow: false,
      class_id: JSON.parse(localStorage.getItem('teachclass_id')), //班级id
      startFlag: false,
      teach_val_length: 0,
      teach_valindex: 0,
      InclassCorrectshow: false,
      teachplanIdPlankind: '', //作业 测试
      teachplanIdType: '',
      teachstart_time: '',
      teachend_time: '',
      teachtime: '',
      teachtimechu: '',
      teachplanIdTime: new Date().valueOf(),
      queshow: false,
      timerdata: 0,
      timer: '',
      usrDotshow: false,
      writingInterfaceshow: false,
      explain_quesshow: true,
      childrenListindex: localStorage.getItem('childrenListindex')
        ? localStorage.getItem('childrenListindex')
        : 0,
      selectmintopicindex: localStorage.getItem('selectmintopicindex')
        ? localStorage.getItem('selectmintopicindex')
        : 0,
      canves_show: false,
      que: null,
      items: null,
      content: null,
      id: null,
      answer: 0,
      Mathqunatishow: false,
      teachexplainShow: false, //测验是否显示倒计时
      queryUserList: null,
      paramsPlanId: null,
      result: {
        questionOutVo: {
          analysis: '',
        },
      },
    };
  },
  filters: {
    //配置过滤器
    Rounding: function(value) {
      //调用时间戳为日期显示
      if (value) {
        value = value.substr(0, value.length - 1);
        let ho;
        let qian;
        if (value.indexOf('.') == -1) {
          qian = value + '.';
          ho = '00';
        } else {
          ho = value.substring(value.indexOf('.') + 1, value.indexOf('.') + 3);
          qian = value.substring(0, value.indexOf('.') + 1);
        }
        if (ho.length > 2) {
          ho = ho.substr(0, 2);
        } else if (ho.length == 1) {
          ho = ho + '0';
        } else if (ho.length == 0) {
          ho = '00';
        }
        return qian + ho;
      } else {
        return value;
      }
    },
    TruncateString: function(value) {
      if (value && value.length > 4) {
        return value.slice(0, 4);
      } else {
        return value;
      }
    },
  },
  created() {
    if (!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
      let error = 'Your browser does NOT support the getDisplayMedia API.';
      throw new Error(error);
    }
    this.paramsPlanId = this.$route.params.plan_id;
    // window.addEventListener('beforeunload', () => this.InclassCorrect('true'))
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e));
    let teachend_time = new Date(
      JSON.parse(localStorage.getItem('teachend_time'))
    );
    this.teachend_time = teachend_time.getTime();
    let teachstart_time = new Date(
      JSON.parse(localStorage.getItem('teachstart_time'))
    );
    this.teachstart_time = teachstart_time.getTime();
    this.teachplanIdPlankind = JSON.parse(
      localStorage.getItem('teachplanIdPlankind')
    );
    this.teachplanIdType = JSON.parse(localStorage.getItem('teachplanIdType'));
    this.teachanswertype = JSON.parse(localStorage.getItem('teachanswertype'));
    if (this.teachplanIdPlankind == '作业' && this.teachplanIdType == '课中') {
      if (this.teachanswertype == '作业本') {
        this.teachplanIdshow = true;
      } else {
        this.teachplanIdshow = false;
      }
    } else {
      this.teachplanIdshow = false;
    }
    this.teachexplain = localStorage.getItem('teachexplain');
    if (this.teachexplain) {
      this.teachexplain = JSON.parse(this.teachexplain);
    }
    if (
      this.teachplanIdPlankind == '测验' &&
      this.teachplanIdTime > this.teachstart_time &&
      this.teachplanIdTime < this.teachend_time &&
      this.teachexplain
    ) {
      this.teachexplainShow = true;
    } else {
      this.teachexplainShow = false;
    }
    // this.$forceUpdate()
    this.LevelSettingQueryList();
    this.initial();
    this.getWebSocket();
    this.QueryUserListByClassId();
  },
  beforeDestroy() {
    if (this.videoStart) {
      this.stopRecording();
    }
    if (this.InclassCorrectshow) {
      this.InclassCorrect('true');
    }
    clearInterval(this.getteachtimeInterval);
  },
  destroyed() {
    this.getWebSocketclose();
    // window.removeEventListener('beforeunload', () => this.InclassCorrect('true'))
    localStorage.removeItem('teachplanIdType');
    localStorage.removeItem('teachclass');
  },
  methods: {
    student_list(e) {
      this.aa = e;
      console.log(this.aa);
    },
    mousedown(ev) {
      var divs = document.getElementById('zhangsan');
      //event的兼容性
      var ev = ev || event;

      //获取鼠标按下的坐标
      var x1 = ev.clientX;
      var y1 = ev.clientY;

      //获取元素的left，top值
      var l = divs.offsetLeft;
      var t = divs.offsetTop;

      //给可视区域添加鼠标的移动事件
      document.onmousemove = function(ev) {
        //event的兼容性
        var ev = ev || event;

        //获取鼠标移动时的坐标
        var x2 = ev.clientX;
        var y2 = ev.clientY;

        //计算出鼠标的移动距离
        var x = x2 - x1;
        var y = y2 - y1;

        //移动的数值与元素的left，top相加，得出元素的移动的距离
        var ls = x + l;
        //更改元素的left，top值
        var content = document.getElementById('container_con_html');
        content.scrollLeft = ls;
        console.log(ls, content.scrollLeft, 'content.scrollLeft');
      };

      //清除
      document.onmouseup = function(ev) {
        document.onmousemove = null;
      };
    },
    // 查询颜色等级
    LevelSettingQueryList() {
      let data = {
        currPage: this.levelSetting.currPage,
        pageSize: this.levelSetting.pageSize,
        classId: JSON.parse(localStorage.getItem('teachclass_id')),
        subjectId: JSON.parse(localStorage.getItem('teachsubjectId')),
      };
      levelSettingQueryList(data).then((res) => {
        this.levelSetting = res.data.result;
        if (this.levelSetting.list && this.levelSetting.list.length > 0)
          this.levelSetting.list.forEach((item) => {
            if (item.code == 'good') {
              this.greaterThan[0].value = item.startScore;
            }
            if (item.code == 'pass') {
              this.greaterThan[1].value = item.startScore;
            }
          });
      });
    },
    // 双指放大
    touchStart(evt) {
      if (evt.touches.length == 1) {
        this.touchStartData = null;
      } else if (evt.touches.length == 2) {
        this.touchStartData = evt.touches;
      }
    },
    touchmove(evt) {
      let originScale;
      if (evt.touches.length == 1) {
        this.touchmoveData = null;
      } else if (evt.touches.length == 2) {
        this.touchmoveData = evt.touches;
        if (this.touchmoveData && this.touchStartData) {
          if (this.originScale < 0.1) {
            originScale = 0.1;
          } else {
            originScale =
              (this.originScale *
                this.getDistance(
                  this.touchmoveData[0],
                  this.touchmoveData[1]
                )) /
              this.getDistance(this.touchStartData[0], this.touchStartData[1]);
          }
          if (this.iTime) {
            clearTimeout(this.iTime);
          }
          this.iTime = setTimeout(() => {
            this.originScale = originScale;
          }, 100);
        }
      }
    },
    getDistance(p1, p2) {
      var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY;
      return Math.sqrt(x * x + y * y);
    },
    // 修改事件
    Rounding: function(value) {
      //调用时间戳为日期显示
      if (value) {
        value = value.substr(0, value.length - 1);
        let ho;
        let qian;
        if (value.indexOf('.') == -1) {
          qian = value + '.';
          ho = '00';
        } else {
          ho = value.substring(value.indexOf('.') + 1, value.indexOf('.') + 3);
          qian = value.substring(0, value.indexOf('.') + 1);
        }
        if (ho.length > 2) {
          ho = ho.substr(0, 2);
        } else if (ho.length == 1) {
          ho = ho + '0';
        } else if (ho.length == 0) {
          ho = '00';
        }
        return qian + ho;
      } else {
        return value;
      }
    },
    // 班级下用户列表
    QueryUserListByClassId() {
      let data = {
        currPage: 1,
        pageSize: 1000,
        id: JSON.parse(localStorage.getItem('teachclass_id')),
        relationKind: 'student',
      };
      queryUserListByClassId(data).then((res) => {
        this.queryUserList = res.data;
      });
    },
    myBoxLeft(index) {
      // var L1 = 0;
      // for (let i = 0; i < index; i++) {
      // 	let oBox = document.getElementById('explain_quesshow' + i);
      // 	//获取元素自身的宽度
      // 	L1 = L1 + oBox.offsetWidth + 20;
      // }
      // document.getElementById("ques").scrollLeft = L1
      // 是否录屏
      if (this.screenRecordingShow) {
        if (this.videoStart) {
          this.parentVideoStart = true;
          this.stopRecording();
        } else {
          this.startRecording();
        }
      }
    },
    GotUserJoinAllStation() {
      let data = {
        planId: JSON.parse(localStorage.getItem('teachplan_id')),
        classId: JSON.parse(localStorage.getItem('teachclass_id')),
      };
      gotUserJoinAllStation(data).then((res) => {
        this.que = baseJs.Adapter3(this.que, res.data.result);
        this.que.forEach((item) => {
          if (item.childrenList) {
            item.childrenList.forEach((items) => {
              if (items.submit_rate) {
                items.submit_rate = this.Rounding(items.submit_rate);
              }
              if (items.right_rate) {
                items.right_rate = this.Rounding(items.right_rate);
              }
            });
          } else {
            if (item.submit_rate) {
              item.submit_rate = this.Rounding(item.submit_rate);
            }
            if (item.right_rate) {
              item.right_rate = this.Rounding(item.right_rate);
            }
          }
        });
        // console.log(que);
      });
    },
    startRecording() {
      // if(JSON.parse(localStorage.getItem('teachchaptersData'))==null){
      // 	this.$message("请选择教学章节");
      // 	return
      // }
      //开始录制
      this.videoStart = true;
      this.screenRecordingShow = true;
      this.$refs['screen-recording'].startRecording((start) => {
        this.start = start;
      });
    },
    stopRecording(data) {
      if (data) {
        this.screenRecordingShow = false;
      }
      this.videoStart = false;

      this.$refs['screen-recording'].stopRecording((start) => {
        this.start = start;
      });
    },
    //流停止监听
    streamStop() {
      if (this.start) {
        this.$refs['screen-recording'].stopRecording((start) => {
          this.start = start;
        });
      }
    },
    getWebSocketclose() {
      clearInterval(this.timer2);
      if (this.ws) {
        this.ws.close();
      }
    },
    getWebSocket() {
      //更新echarts
      let params1 = 'KS0015' + JSON.parse(localStorage.getItem('teachplan_id'));
      const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket';
      WebSocketFunc(
        this.ws,
        params1,
        url,
        (data) => {
          data = JSON.parse(data);
          if (data.flag != '9') {
            this.GotUserJoinAllStation();
            this.mychartenlarge();
          }
        },
        (ws) => {
          this.ws = ws;
        },
        (timer) => {
          this.timer2 = timer;
        }
      );
    },
    beforeunloadFn(e) {
      if (this.InclassCorrectshow) {
        this.InclassCorrect('true');
      }
    },
    initial() {
      if (this.teachexplainShow) {
        this.teachtimechu = this.teachend_time - this.teachplanIdTime;
        this.getteachtime();
        this.getteachtimeInterval = setInterval(() => {
          this.getteachtime();
        }, 1000);
      } else {
        this.getitems();
      }
    },
    getteachtime() {
      this.teachtimechu = this.teachtimechu - 1000;
      let leftd = Math.floor(this.teachtimechu / (1000 * 60 * 60 * 24)); //计算天数
      let lefth = Math.floor((this.teachtimechu / (1000 * 60 * 60)) % 24); //计算小时数
      let leftm = Math.floor((this.teachtimechu / (1000 * 60)) % 60); //计算分钟数
      let lefts = Math.floor((this.teachtimechu / 1000) % 60); //计算秒数
      this.teachtime =
        leftd +
        '天' +
        (lefth > 9 ? lefth : '0' + lefth) +
        ':' +
        (leftm > 9 ? leftm : '0' + leftm) +
        ':' +
        (lefts > 9 ? lefts : '0' + lefts);
      this.$forceUpdate();
    },
    kong(data) {
      this.kongInclassCorrectshow(data);
    },
    InclassCorrect(data) {
      let teach_val = JSON.parse(localStorage.getItem('teach_val'));
      if (
        teach_val.childrenList &&
        teach_val.childrenList.length > 0 &&
        !data
      ) {
        this.InclassCorrectshow = false;
        this.$message({
          message: '请选择小题',
          type: 'warning',
        });
        return;
      }
      if (data) {
        this.InclassCorrectshow = false;
      } else {
        this.InclassCorrectshow = !this.InclassCorrectshow;
      }
      localStorage.setItem(
        'teachsetime',
        JSON.stringify(Date.parse(new Date()))
      );
      if (!teach_val.answerAreaList[this.teach_valindex]) {
        this.teach_valindex = 0;
      }
      let classids = null;
      if (localStorage.getItem('teachusertype') == 'remote_teacher') {
        JSON.parse(localStorage.getItem('teachteachclasss')).forEach(
          (item, index) => {
            if (index == '0') {
              classids = item.id;
            } else {
              classids = classids + ',' + item.id;
            }
          }
        );
      } else {
        classids = JSON.parse(localStorage.getItem('teachclass_id'));
      }
      let params = {
        classId: classids,
        planId: JSON.parse(localStorage.getItem('teachplan_id')),
        questionId: teach_val.questionOutVo.id,
        subId: teach_val.questionOutVo.id,
        score: teach_val.answerAreaList[this.teach_valindex].score
          ? teach_val.answerAreaList[this.teach_valindex].score
          : '0',
        serial: teach_val.answerAreaList[this.teach_valindex].serialAnswer,
        answer: '',
        beginTime: Date.parse(new Date()),
        subKind: 'question',
        customResources:
          teach_val.questionOutVo.answerList[this.teach_valindex]
            .customResources,
        pageId: teach_val.answerAreaList[this.teach_valindex].pageId,
        subParentId: teach_val.questionOutVo.parentId
          ? teach_val.questionOutVo.parentId
          : 0,
        subject_code: teach_val.questionOutVo.subjectCode,
        question_type_code: teach_val.questionOutVo.featureCode,
        is_start: this.InclassCorrectshow,
        vipcode: '88888888',
        is_kafka_push: true,
      };
      params.answer = [];
      params.answer.push(
        teach_val.questionOutVo.answerList[this.teach_valindex].correctValue
      );
      if (
        teach_val.questionOutVo.answerList[this.teach_valindex].answerExtendList
      ) {
        teach_val.questionOutVo.answerList[
          this.teach_valindex
        ].answerExtendList.forEach((item) => {
          params.answer.push(item.value);
        });
      }
      this.Save(teach_val);
      correction_in_class(params).then((res) => {
        if (!this.InclassCorrectshow) {
          this.teach_valindex = 0;
          localStorage.setItem('teach_kongindex', this.teach_valindex);
        }
      });
    },
    Save(data) {
      save({
        planId: JSON.parse(localStorage.getItem('teachplan_id')),
        questionId: data.questionOutVo.id,
        startFlag: this.InclassCorrectshow,
        serial: this.teach_valindex + 1,
        referenceType: 'class',
        referenceId:
          localStorage.getItem('teachusertype') == 'remote_teacher'
            ? 0
            : JSON.parse(localStorage.getItem('teachclass_id')),
      }).then((res) => {});
    },
    Model_data_click(second_time) {
      var time =
        '00:00:' +
        (parseInt(second_time) > 9
          ? parseInt(second_time)
          : '0' + parseInt(second_time));
      if (parseInt(second_time) > 60) {
        var second = parseInt(second_time) % 60;
        var min = parseInt(second_time / 60);
        time =
          '00:' +
          (min > 9 ? min : '0' + min) +
          ':' +
          (second > 9 ? second : '0' + second);
        if (min > 60) {
          min = parseInt(second_time / 60) % 60;
          var hour = parseInt(parseInt(second_time / 60) / 60);
          time =
            (hour > 9 ? hour : '0' + hour) +
            ':' +
            (min > 9 ? min : '0' + min) +
            ':' +
            (second > 9 ? second : '0' + second);
          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24;
            var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
            time =
              day +
              '天' +
              (hour > 9 ? hour : '0' + hour) +
              ':' +
              (min > 9 ? min : '0' + min) +
              ':' +
              (second > 9 ? second : '0' + second);
          }
        }
      }
      return time;
    },
    Mathqunati() {
      this.$nextTick(function() {
        //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
        if (this.commonsVariable.isMathjaxConfig) {
          //判断是否初始配置，若无则配置。
          this.commonsVariable.initMathjaxConfig();
        }
        this.commonsVariable.MathQueue('container');
        this.Mathqunatishow = true;
      });
    },
    mychartenlarge(data) {
      if (data) {
        //如果是双击放大的话
        this.$refs.myecharts.refreshEchart = true;
      } else {
        //webscoket传过来的值
        this.$refs.myecharts.refreshEchart = false;
      }
      // this.$refs.myecharts.refreshEchart=false
      this.$refs.myecharts.sonchartenlarge(data);
    },
    writingInterface() {
      this.writingInterfaceshow = true;
    },
    //文字识别
    close(val) {
      this.canves_show = val;
    },
    rollImg() {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
      zoom += event.wheelDelta / 12;
      if (zoom > 0) this.$refs.imgDiv.style.zoom = zoom + '%';
      return false;
    },
    rollImg1() {
      var zoom = parseInt(this.$refs.imgDiv1.style.zoom) || 100;
      zoom += event.wheelDelta / 12;
      if (zoom > 0) this.$refs.imgDiv1.style.zoom = zoom + '%';
      return false;
    },
    explain_return() {
      this.$router.back(-1);
    },
    handleclicks() {
      //切换题先把要给学生列表的正确学生错误学生数据清空掉
      this.getStudentList([]);

      if (localStorage.getItem('teach_kongindex')) {
        localStorage.removeItem('teach_kongindex');
      }
      this.kongInclassCorrectshow();
    },
    kongInclassCorrectshow(data) {
      if (this.InclassCorrectshow) {
        let teach_val = JSON.parse(localStorage.getItem('teach_val'));
        if (teach_val.childrenList && teach_val.childrenList.length > 0) {
          this.InclassCorrectshow = false;
          this.$message({
            message: '请选择小题',
            type: 'warning',
          });
          return;
        }
        this.InclassCorrectshow = !this.InclassCorrectshow;
        localStorage.setItem(
          'teachsetime',
          JSON.stringify(Date.parse(new Date()))
        );
        if (!teach_val.answerAreaList[this.teach_valindex]) {
          this.teach_valindex = 0;
        }
        let classids = null;
        if (localStorage.getItem('teachusertype') == 'remote_teacher') {
          JSON.parse(localStorage.getItem('teachteachclasss')).forEach(
            (item, index) => {
              if (index == '0') {
                classids = item.id;
              } else {
                classids = classids + ',' + item.id;
              }
            }
          );
        } else {
          classids = JSON.parse(localStorage.getItem('teachclass_id'));
        }
        let params = {
          classId: classids,
          planId: JSON.parse(localStorage.getItem('teachplan_id')),
          questionId: teach_val.questionOutVo.id,
          subId: teach_val.questionOutVo.id,
          score: teach_val.answerAreaList[this.teach_valindex].score
            ? teach_val.answerAreaList[this.teach_valindex].score
            : '0',
          serial: teach_val.answerAreaList[this.teach_valindex].serialAnswer,
          answer: [],
          beginTime: Date.parse(new Date()),
          subKind: 'question',
          customResources:
            teach_val.questionOutVo.answerList[this.teach_valindex]
              .customResources,
          pageId: teach_val.answerAreaList[this.teach_valindex].pageId,
          subParentId: teach_val.questionOutVo.parentId
            ? teach_val.questionOutVo.parentId
            : 0,
          subject_code: teach_val.questionOutVo.subjectCode,
          question_type_code: teach_val.questionOutVo.featureCode,
          is_start: this.InclassCorrectshow,
          vipcode: '88888888',
          is_kafka_push: true,
          // vipcode:JSON.parse(localStorage.getItem('teachusername'))=='13381537902'?'88888888':'0'
        };
        params.answer.push(
          teach_val.questionOutVo.answerList[this.teach_valindex].correctValue
        );
        if (
          teach_val.questionOutVo.answerList[this.teach_valindex]
            .answerExtendList
        ) {
          teach_val.questionOutVo.answerList[
            this.teach_valindex
          ].answerExtendList.forEach((item) => {
            params.answer.push(item.value);
          });
        }
        if (data) {
          if (data == 'jia') {
            if (this.teach_valindex < this.teach_val_length - 1) {
              this.Save(teach_val);
              correction_in_class(params).then((res) => {
                this.teach_valindex = this.teach_valindex + 1;
                this.$message({
                  message: '第' + (this.teach_valindex + 1) + '空',
                  type: 'warning',
                });
                this.InclassCorrect();
                localStorage.setItem('teach_kongindex', this.teach_valindex);
              });
            } else {
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
              this.InclassCorrectshow = !this.InclassCorrectshow;
            }
          } else {
            if (this.teach_valindex > 0) {
              this.Save(teach_val);
              correction_in_class(params).then((res) => {
                this.teach_valindex = this.teach_valindex - 1;
                this.$message({
                  message: '第' + (this.teach_valindex + 1) + '空',
                  type: 'warning',
                });
                this.InclassCorrect();
                localStorage.setItem('teach_kongindex', this.teach_valindex);
              });
            } else {
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
              this.InclassCorrectshow = !this.InclassCorrectshow;
            }
          }
        } else {
          this.Save(teach_val);
          correction_in_class(params).then((res) => {
            this.teach_valindex = 0;
            this.InclassCorrect();
            localStorage.setItem('teach_kongindex', this.teach_valindex);
          });
        }
      } else {
        if (data) {
          if (data == 'jia') {
            if (this.teach_valindex < this.teach_val_length - 1) {
              this.teach_valindex = this.teach_valindex + 1;
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
              localStorage.setItem('teach_kongindex', this.teach_valindex);
            } else {
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
            }
          } else {
            if (this.teach_valindex > 0) {
              this.teach_valindex = this.teach_valindex - 1;
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
              localStorage.setItem('teach_kongindex', this.teach_valindex);
            } else {
              this.$message({
                message: '第' + (this.teach_valindex + 1) + '空',
                type: 'warning',
              });
            }
          }
        } else {
          this.teach_valindex = 0;
          localStorage.setItem('teach_kongindex', this.teach_valindex);
        }
      }
    },
    handleclick: function(data, val, index, childrenListindex) {
      if (childrenListindex || childrenListindex == 0) {
        this.childrenListindex = childrenListindex;
        localStorage.setItem('childrenListindex', childrenListindex);
      } else {
        console.log('111');
        this.childrenListindex = null;
        localStorage.removeItem('childrenListindex');
        this.content = this.textcircled(val.questionOutVo.content);
      }
      if (index || index == 0) {
        console.log(index);
        this.selectmintopicindex = index;
        localStorage.setItem('selectmintopicindex', index);
      } else {
        this.selectmintopicindex = localStorage.getItem('selectmintopicindex')
          ? localStorage.getItem('selectmintopicindex')
          : 0;
      }
      localStorage.setItem('teachquestion_id', JSON.stringify(data));
      this.content = this.textcircled(val.questionOutVo.content);
      val.answerAreaList = this.duplicateRemoval(val.answerAreaList);
      localStorage.setItem('teach_val', JSON.stringify(val));
      this.$store.state.teach_val = JSON.stringify(val);
      this.teach_val_length = val.answerAreaList.length;
      localStorage.setItem(
        'teach_val_length',
        JSON.stringify(val.answerAreaList.length)
      );
      localStorage.setItem(
        'teach_featureCode',
        JSON.stringify(val.questionOutVo.featureCode)
      );
      this.Mathqunati();
      if (this.$refs.myecharts) {
        this.$nextTick(() => {
          if (
            typeof this.$refs.myecharts.getChoice != 'undefined' &&
            this.$refs.myecharts.getChoice instanceof Function
          )
            this.$refs.myecharts.refreshEchart = true;
          this.$refs.myecharts.getChoice();
        });
      }
      if (this.screenRecordingShow && this.videoStart) {
        this.startRecording();
      }
    },
    duplicateRemoval(arr) {
      var result = [];
      var obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i].serial]) {
          result.push(arr[i]);
          obj[arr[i].serial] = true;
        }
      }
      return result;
    },
    handleclick1: function(data) {
      if (
        JSON.parse(localStorage.getItem('teachplanIdPlankind')) == '作业' &&
        JSON.parse(localStorage.getItem('teachplanIdType')) == '课中' &&
        JSON.parse(localStorage.getItem('teachanswertype')) == '作业本'
      ) {
      } else {
        if (data.user_id) {
          this.userId = data.user_id;
        } else {
          this.queryUserList.result.list.forEach((item) => {
            if (item.name == data) {
              this.userId = item.id;
            }
          });
        }
        this.usrDotshow = true;
        this.$nextTick(() => {
          this.$refs.Correct.get_correction_from_plan(this.userId);
        });
      }
    },
    //点击题情按键触发
    gettiqingrecursion(item) {
      if (
        Object.prototype.toString.call(item.childrenList) ===
          '[object Array]' &&
        item.childrenList.length > 0
      ) {
        item.childrenList.forEach((items) => {
          this.gettiqingrecursion(items);
        });
      } else {
        if (item.id == JSON.parse(localStorage.getItem('teachquestion_id'))) {
          let data = item.questionOutVo.analysis;
          item.questionOutVo.analysis = this.textcircled(data);
          if (item.questionOutVo.analysis) {
            item.questionOutVo.analysis = item.questionOutVo.analysis.replace(
              /&amp;nbsp;/g,
              ''
            );
          }
          item.questionOutVo.answerList.forEach(
            (answerListitem, answerListindex) => {
              if (answerListitem.value.indexOf('@@@@') != -1) {
                answerListitem.value = answerListitem.value.replace(
                  /@@@@/g,
                  ''
                );
                answerListitem.value = answerListitem.value.replace(/\$/g, '');
              }
              if (answerListitem.value) {
                answerListitem.value = answerListitem.value.replace(
                  /&amp;nbsp;/g,
                  ''
                );
              }
              answerListitem.value = this.textcircled(answerListitem.value);
            }
          );
          this.result = item;
          this.Mathqunati();
          this.$forceUpdate();
          this.answer = 1;
        }
      }
    },
    textcircled(val) {
      if (val) {
        let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫'];
        let data = val.replace(/\\textcircled {(1)}/g, a[0]);
        data = data.replace(/\\textcircled {(2)}/g, a[1]);
        data = data.replace(/\\textcircled {(3)}/g, a[2]);
        data = data.replace(/\\textcircled {(4)}/g, a[3]);
        data = data.replace(/\\textcircled {(5)}/g, a[4]);
        data = data.replace(/\\textcircled {(6)}/g, a[5]);
        data = data.replace(/\\textcircled {(7)}/g, a[6]);
        data = data.replace(/\\textcircled {(8)}/g, a[7]);
        data = data.replace(/\\textcircled {(9)}/g, a[8]);
        data = data.replace(/\\textcircled {(10)}/g, a[9]);
        data = data.replace(/\\textcelsius/g, '度');
        data = data.replace(/&amp;nbsp;/g, ' ');
        return data;
      }
    },
    //点击题情按键1-1
    gettiqing() {
      this.que.forEach((item) => {
        this.gettiqingrecursion(item);
      });
    },
    getitems() {
      let that = this;
      getItems({
        onlyParent: 1,
        userToken: JSON.parse(localStorage.getItem('teachuserToken')), //要给的
        plan_id: JSON.parse(localStorage.getItem('teachplan_id')), //点击题目有的
        class_id: this.class_id, //要给的
        relation_kind: JSON.parse(localStorage.getItem('teachrelation_kind')), //点击题目有的
        user_id: 0,
      }).then((response) => {
        this.que = response.data.data;
        // console.log(that.que, 'studentList:', that.$store.state.StudentList);
        if (this.que && this.que.length > 0) {
          this.que.forEach((item) => {
            if (item.id == '89700') {
              console.log(item);
            }
            if (item.childrenList) {
              item.childrenList.forEach((items) => {
                if (items.submit_rate) {
                  items.submit_rate = this.Rounding(items.submit_rate);
                }
                if (items.right_rate) {
                  items.right_rate = this.Rounding(items.right_rate);
                }
              });
            } else {
              if (item.submit_rate) {
                item.submit_rate = this.Rounding(item.submit_rate);
              }
              if (item.right_rate) {
                item.right_rate = this.Rounding(item.right_rate);
              }
            }
          });
          if (!localStorage.getItem('teachquestion_id')) {
            this.que ? this.handleclick(this.que[0].id, this.que[0], 0) : '';
          } else {
            this.que.forEach((item, index) => {
              if (item.id == localStorage.getItem('teachquestion_id')) {
                this.que ? this.handleclick(item.id, item, index) : '';
                this.queshow = true;
              } else {
                if (item.childrenList && item.childrenList.length > 0) {
                  item.childrenList.forEach((items, indexs) => {
                    if (items.id == localStorage.getItem('teachquestion_id')) {
                      this.que
                        ? this.handleclick(items.id, items, index, indexs)
                        : '';
                      this.queshow = true;
                    }
                  });
                }
              }
            });
            if (!this.queshow) {
              this.que ? this.handleclick(this.que[0].id, this.que[0]) : '';
            }
          }
          if (!this.Mathqunatishow) {
            this.Mathqunati();
          }
        }
      });
    },
    realtimeAnswer() {
      realTimeAnswer({
        userToken: JSON.parse(localStorage.getItem('teachuserToken')),
        plan_id: JSON.parse(localStorage.getItem('teachplan_id')),
        class_id: this.class_id,
      }).then((response) => {
        this.que = response.data.data;
        if (!localStorage.getItem('teachquestion_id')) {
          this.que ? this.handleclick(this.que[0].id, this.que[0]) : '';
        } else {
          this.que.forEach((item, index) => {
            if (item.id == localStorage.getItem('teachquestion_id')) {
              this.que ? this.handleclick(item.id, item) : '';
            }
          });
        }
      });
    },
    ...mapMutations(['getStudentList']),
  },
};
</script>

<style scoped="scoped">
::v-deep .el-dialog__body {
  padding: 5px 20px;
}
.echarts-init {
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
}
.explain_right {
  margin-bottom: 20px;
}

.queclass {
  position: absolute;
  bottom: 10%;
  background: #fff;
  width: 668px;
  box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 30%),
    0px 8px 16px -4px rgb(9 30 66 / 26%);
  text-align: left;
  overflow-y: auto;
  max-height: 50vh;
}

.selectmintopicindex {
  display: inline-block;
  line-height: 39px;
  margin: 10px;
}

.selectmintopicindexs {
  /* background-color: #5D94E6; */
  /* background: rgba(red, green, blue, alpha); */
  color: #409eff;
  display: inline-block;
  line-height: 39px;
  margin: 10px;
}

.selectmintopic {
  background-color: #ecf5ff;
  color: #409eff;
}

.selectmintopictext {
  font-weight: 800;
  border-radius: 6px;
}

.topicSituationBase {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #071737;
}

.topicSituationBase > p {
  margin: 4px 0 0;
}

.font_size {
  font-size: 40px;
}

.font_size1 {
  font-size: 20px;
}
.container_con_htmltigan {
  font-size: 46px;
}

.explain_answer_con {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(9, 30, 66, 0.05);
}

.explain_answer_con > div {
  width: 80%;
  height: 80%;
  background-color: #ffffff;
  position: absolute;
  left: 10%;
  top: 10%;
  display: flex;
}

.explain_answer_con1 {
  width: 30%;
  text-align: left;
  padding-left: 20px;
}

.explain_answer_con1_Base {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #071737;
  line-height: 28px;
  margin-top: 24px;
}

.explain_answer_con2 {
  width: 80%;
  text-align: left;
  padding-left: 20px;
  overflow: auto;
}

.container_con {
  position: relative;
  background-color: #fff;
}

.container_con_html {
  padding: 20px;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
}

.explain_Answer1 {
  padding: 0 0.75%;
  background-color: #fff;
  border-right: 1px solid #d8deea;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.explain_Answer2 {
  padding: 0 0.75%;
  background-color: #fff;
  border-left: 1px solid #d8deea;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.explain_ques {
  padding: 0 20px;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  background-color: #ffffff;
  /* justify-content: space-around; */
}

.col-center-block {
  float: none;
  display: block;
  margin: 0 auto;
}

.aboust_bott20 {
  white-space: nowrap;
}

.aboust_top20 {
  white-space: nowrap;
}

.container_con1 {
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.explain_answer_con1_Base_title {
  margin-top: 0;
}
</style>
<style>
.content-img-width p img {
  width: 100%;
}
</style>
