<template>
	<div class="dot">
		<div class="dot_con">
			<Correct v-for="(item,index) in linestatusindex" :ref="'className'+index" :className="'className'+index"
				:classItem="item"></Correct>
		</div>
		<div class="dot_bottm">
			<div class="dot_bottm_top" v-if="lineshow">
				<div class="dot_bottm_top_top">
					<div class="dot_bottm_top_top_left">
						<span v-if="online.length>0" @click="lineindex='1'"
							:style="{'background':lineindex=='1'? '#fff':''}"><img style="margin-right: 8px;"
								src="../assets/img/online.png">{{$store.state.teachlanguagedata.connected}}</span>
						<span v-if="offline.length>0" @click="lineindex='2'"
							:style="{'background':lineindex=='2'? '#fff':''}"><img style="margin-right: 8px;"
								src="../assets/img/offline.png">{{$store.state.teachlanguagedata.unconnected}}</span>
						<span v-if="unbound.length>0" @click="lineindex='3'"
							:style="{'background':lineindex=='3'? '#fff':''}">{{$store.state.teachlanguagedata.Penunboundservice}}</span>
						<span v-if="unrelation.length>0" @click="lineindex='4'"
							:style="{'background':lineindex=='4'? '#fff':''}">{{$store.state.teachlanguagedata.Noonetiedapen}}</span>
						<span>{{$store.state.teachlanguagedata.Youcanonlychooseupto3students}}</span>
					</div>
					<div class="dot_bottm_top_top_right">
						<span class="dot_bottm_top_top_right_clear" @click="tableClear()">{{$store.state.teachlanguagedata.empty}}</span>
						<span style="padding-right:16px;" @click="lineshow=false">X</span>
					</div>
				</div>
				<div class="dot_bottm_top_con">
					<div v-if="lineindex=='1'">
						<span v-for="(item,index) in online" @click="tableName(item,index)"
							:style="{'background':((linestatusindex[0]&&linestatusindex[0].pen_mac==item.pen_mac&&linestatusindex[0].name==item.name)||(linestatusindex[1]&&linestatusindex[1].pen_mac==item.pen_mac&&linestatusindex[1].name==item.name)||(linestatusindex[2]&&linestatusindex[2].pen_mac==item.pen_mac&&linestatusindex[2].name==item.name))? '#DEEBFF':''}">
							{{item.mobile?item.mobile:item.name}}
						</span>
					</div>
					<div v-if="lineindex=='2'">
						<span v-for="(item,index) in offline" @click="tableName(item,index)"
							:style="{'background':((linestatusindex[0]&&linestatusindex[0].pen_mac==item.pen_mac&&linestatusindex[0].name==item.name)||(linestatusindex[1]&&linestatusindex[1].pen_mac==item.pen_mac&&linestatusindex[1].name==item.name)||(linestatusindex[2]&&linestatusindex[2].pen_mac==item.pen_mac&&linestatusindex[2].name==item.name))? '#DEEBFF':''}">
							{{item.mobile?item.mobile:item.name}}
						</span>
					</div>
					<div v-if="lineindex=='3'">
						<span v-for="(item,index) in unbound"
							:style="{'background':((linestatusindex[0]&&linestatusindex[0].pen_mac==item.pen_mac&&linestatusindex[0].name==item.name)||(linestatusindex[1]&&linestatusindex[1].pen_mac==item.pen_mac&&linestatusindex[1].name==item.name)||(linestatusindex[2]&&linestatusindex[2].pen_mac==item.pen_mac&&linestatusindex[2].name==item.name))? '#DEEBFF':''}">
							{{item.mobile?item.mobile:item.name}}
						</span>
					</div>
					<div v-if="lineindex=='4'">
						<span v-for="(item,index) in unrelation"
							:style="{'background':((linestatusindex[0]&&linestatusindex[0].pen_mac==item.pen_mac&&linestatusindex[0].name==item.name)||(linestatusindex[1]&&linestatusindex[1].pen_mac==item.pen_mac&&linestatusindex[1].name==item.name)||(linestatusindex[2]&&linestatusindex[2].pen_mac==item.pen_mac&&linestatusindex[2].name==item.name))? '#DEEBFF':''}">
							{{item.mobile?item.mobile:item.name}}
						</span>
					</div>
				</div>
			</div>
			<div class="dot_bottm_bottm">
				<div @click="lineshow=!lineshow">
					{{$store.state.teachlanguagedata.students}}
				</div>
				<div @click="canves_show=!canves_show">
					{{$store.state.teachlanguagedata.comments}}
				</div>
			</div>
		</div>
		<Board v-if="canves_show" v-on:close="close"></Board>
	</div>
</template>

<script>
	import {
		getEboxStates,
		queryGroupByStatusByMacs,
		queryMacListByClassId
	} from '../api/Explain.js'
	import {
		getCorrectionFromPlan
	} from '../api/Correct.js'
	import Correct from './Corrects.vue'
	import Board from './CanvasBoard/index.vue'
	export default {
		data() {
			return {
				canves_show:false,
				img_listIndex:0,
				class_id: localStorage.getItem("teachclass_id")?JSON.parse(localStorage.getItem("teachclass_id")):this.$store.state.teachclass.id,
				linestatusindex: [],
				linestatus: null,
				lineshow: true,
				lineindex: 1,
				offline: [],
				online: [],
				unbound: [],
				unrelation: [],
				teachQuestionSetInformation:{},
			};
		},
		computed: {
			changeMemberTab() {
				return this.$store.state.teach_val;
			}
		},
		watch: {
			changeMemberTab(val) {
				this.linestatusindex.forEach((item, index) => {
					let a = 'className' + index
					this.$refs[a][0].setimeteach_val()
				})
			},
		},
		components: {
			Correct,Board
		},
		mounted() {
			// this.img_listIndex=localStorage.getItem("teach_val")?JSON.parse(localStorage.getItem("teach_val")).questionAreaList[0].pageNo-1:0
			this.img_listIndex=0
			this.teachQuestionSetInformation=JSON.parse(localStorage.getItem('teachQuestionSetInformation'))
			this.initial()
		},
		methods: {
			//文字识别
			close(val) {
				this.canves_show = val
			},
			initial(){
				this.linkStatus()
				this.get_correction_from_plan();
			},
			parentref() {
				this.$bus.$emit('addMethoc')
			},
			clearwekcoket() {
				this.linestatusindex.forEach((item, index) => {
					let a = 'className' + index
					this.$refs[a][0].getWebSocketclose()
				})
			},
			get_correction_from_plan() {
				getCorrectionFromPlan({
					"plan_id": JSON.parse(localStorage.getItem("teachplan_id")),
					"userID": JSON.parse(localStorage.getItem("teachuserID")),
					"setId": JSON.parse(localStorage.getItem("teachsetId")),
					"class_id": this.class_id
				}).then(response => {
					localStorage.setItem("teachimg_list", JSON.stringify(response.data.img_list))
					localStorage.setItem("teachimg_list", JSON.stringify(response.data.img_list))
					localStorage.setItem("teachpageIds", JSON.stringify(response.data.pageIds))
				})
			},
			tableClear() {
				this.clearwekcoket()
				this.linestatusindex = []
			},
			tableName(item, index) {
				if (this.findIndexByKeyValue(this.linestatusindex, 'name', item.name) == -1) {
					if (this.linestatusindex.length > 2) {
						this.linestatusindex.splice(0,1)
						localStorage.setItem('teachClassid'+item.id,JSON.stringify(this.img_listIndex))
						// localStorage.removeItem('teachClassid'+this.linestatusindex[0].id)
						this.linestatusindex.push(item)
					} else {
						this.linestatusindex.push(item)
						localStorage.setItem('teachClassid'+item.id,JSON.stringify(this.img_listIndex))
					}
				}else{
					let n;
					for(let i=0;i<this.linestatusindex.length;i++){
						if(this.linestatusindex[i].id==item.id){
							n=i
						}
					}
					localStorage.removeItem('teachClassid'+item.id)
					this.linestatusindex.splice(n,1)
				}
			},
			findIndexByKeyValue(arr, key, valuetosearch) {
				for (var i = 0; i < arr.length; i++) {
					if (arr[i][key] == valuetosearch) {
						return i;
					}
				}
				return -1;
			},
			linkStatus() {
				let data= {
					classIds: [this.class_id],
					"planId": JSON.parse(localStorage.getItem("teachplan_id")),
				}
				if(this.teachQuestionSetInformation.roomId){
					data.startTime=this.teachQuestionSetInformation.beginTime
					data.endTime=this.teachQuestionSetInformation.endTime
				}
				queryMacListByClassId(data).then(res=>{
					let penBoxdata = {
						penBoxMacList: []
					}
					let penBoxlist = []
					res.data.result.forEach(item=>{
						if(item.penMac){
							let penBoxarrny = {
								penmac: '',
								name: '',
								id: ''
							}
							penBoxdata.penBoxMacList.push(item.penMac)
							penBoxarrny.pen_mac = item.penMac
							penBoxarrny.name = item.name
							penBoxarrny.id = item.id
							penBoxarrny.mobile = item.mobile
							penBoxlist.push(penBoxarrny)
						}else{
							let penBoxarrny = {
								name: '',
								id: ''
							}
							penBoxarrny.name = item.name
							penBoxarrny.id = item.id
							penBoxarrny.mobile = item.mobile
							this.unrelation.push(penBoxarrny)
						}
					})
					queryGroupByStatusByMacs(penBoxdata).then(res => {
						for (let key in res.data.result) {
							this[key] = res.data.result[key]
							if (this[key].length > 0) {
								for (let i = 0; i < this[key].length; i++) {
									for (let j = 0; j < penBoxlist.length; j++) {
										if (penBoxlist[j].pen_mac == this[key][i].mac) {
											this[key][i].pen_mac = penBoxlist[j].pen_mac
											this[key][i].name = penBoxlist[j].name
											this[key][i].id = penBoxlist[j].id
											this[key][i].mobile = penBoxlist[j].mobile
										}
									}
								}
							}
						}
						if (this.online && this.online.length > 0) {
							this.lineindex = 1
						} else {
							if (this.offline && this.offline.length > 0) {
								this.lineindex = 2
							} else {
								if (this.unbound && this.unbound.length > 0) {
									this.lineindex = 3
								} else {
									if (this.unrelation && this.unrelation.length > 0) {
										this.lineindex = 4
									}
								}
							}
						}
						this.$forceUpdate()
					})
				})
			},
		},
	}
</script>
<style lang="scss">

</style>
