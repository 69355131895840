<template>
	<div class="className">
		<div class="className_con">
			<canvas :id="className" :width="canvesWidth" :height="canvesHeight" @click="canvasclick"></canvas>
			<div class="className_con_bott">
				<div class="className_con_bott_left">
					<img style="margin-right: 8px;" v-if="classItem.state==1" src="../assets/img/online.png">
					<img style="margin-right: 8px;" v-if="classItem.state==0" src="../assets/img/offline.png">
					<span>{{classItem.name}}</span>
				</div>
				<div class="className_con_bott_con">   
					<!-- 这里切换课前课后学生笔迹上一页  下一页 -->
					<span class="className_con_bott_con_span" @click="shangyyiye()"><img
							src="../assets/img/previousPage.png"></span>
					<span>{{pictureSize=='A3'?(img_listIndex+2)/2:img_listIndex+1}}/{{pictureSize=='A3'?img_list.length/2:img_list.length}}</span>
					<span class="className_con_bott_con_span" @click="xiayiye()"><img
							src="../assets/img/nextPage.png"></span>
				</div>
				<div class="className_con_bott_right">
					<el-button @click="Repaint()" style="padding:5px 10px;">{{$store.state.teachlanguagedata.redraw}}
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WebSocketFunc
	} from '../utils/src_utils_socket.js'
	import {
		getdotsv3,
		Recorreted,
		batch_auxiliary_correction,
		addUserExtendAnswerList,
		queryPlanUserJoinDetail,
		getStudentResult
	} from '../api/Correct.js'
	import {
		queryQuestionTeachClass,
		save,
		queryPlanUserJoinRecords
	} from '../api/Explain.js'
	export default {
		name: 'Class',
		data() {
			return {
				dots_draw1data: 1,
				canvesn: 1,
				timer2: '',
				dots_drawindex: 0,
				img_listIndex: localStorage.getItem('teachClassid' + this.classItem.id) ? JSON.parse(localStorage.getItem(
					'teachClassid' + this.classItem.id)) : 0,
				canvesWidth: 516.7,
				imgWidth:'',
				pictureSize:null,//
				imgHeight:'',
				canvesHeight: 730.8,
				teach_val: '',
				img_list: JSON.parse(localStorage.getItem("teachimg_list")),
				pageIds: JSON.parse(localStorage.getItem("teachpageIds")),
			}
		},
		props: [
			'className',
			'classItem'
		],
		watch: {
			classItem(val) {
				this.getWebSocketclose()
				this.img_listIndex = 0
				this.init_canvas()
			},
		},
		mounted() {
			this.init_canvas()
		},
		methods: {
			//老师批改上一级
			recorretedLevel(){
				
			},
			//老师批改
			recorreted(answerAreaitem, item, result, score) {
				let data = {
					plan_id: this.planId,
					student_id: this.userId,
					mac: this.studentData.PenMac,
					begin_time: this.planData.beginTime,
					end_time: this.planData.endTime,
					pageId: this.pageId,
					questionId: item.id,
					parentId: baseJs.orgChildIdFindParent([item.id], this.baseTopicInformation).length > 0 ? baseJs
						.orgChildIdFindParent([item.id], this.baseTopicInformation)[0] : 0,
					serialAnswer: answerAreaitem.serialAnswer,
					manual_result: answerAreaitem.score==score?1:result ? result : 0,
					similarity: answerAreaitem.score==score?1:result ? result : 0,
					score: score?score:answerAreaitem.score?0:-1,
					startX:answerAreaitem.startX,
					endX:answerAreaitem.endX,
					startY:answerAreaitem.startY,
					endY:answerAreaitem.endX,
				}
				Recorreted(data).then(res => {
					if(res.data.msg=="无学生书写数据，无法手动批改"){
						this.$message("无学生书写数据，无法手动批改")
						return
					}
					this.QueryPlanUserMaxRecords()
				})
			},
			teachercalibrate(val, questionId) {
				JSON.parse(localStorage.getItem('teachusername'))
				let data = {
					plan_id: JSON.parse(localStorage.getItem("teachplan_id")),
					student_id: this.classItem.id,
					mac: this.classItem.mac,
					// begin_time: new Date(JSON.parse(localStorage.getItem("teachstart_time"))).getTime(),
					// end_time: new Date(JSON.parse(localStorage.getItem("teachend_time"))).getTime(),
					begin_time: new Date(JSON.parse(localStorage.getItem("teachstart_time"))).getTime(),
					end_time: new Date(JSON.parse(localStorage.getItem("teachend_time"))).getTime(),
					pageId: 0,
					questionId: questionId,
					parentId: val.parentId,
					serialAnswer: val.serialAnswer,
					manual_result: this.Rightwrongindex,
					similarity: this.Rightwrongindex,
					score: -1,
				}
				let data1 = {
					plan_id: JSON.parse(localStorage.getItem("teachplan_id")),
					questionId: questionId,
					studentId: this.classItem.id,
					serialId: val.serialAnswer,
					stand_score: -1,
					question_type_code: this.teach_val.questionOutVo.featureCode,
					subject_code: this.teach_val.questionOutVo.subjectCode,
					customResources: this.teach_val.questionOutVo.answerList[val.serialAnswer - 1].customResources,
					vipcode: '88888888'
				}
				let data2 = {
					"planId": JSON.parse(localStorage.getItem("teachplan_id")),
					"questionId": questionId,
					"serial": val.serialAnswer,
					"userId": this.classItem.id,
					classId: JSON.parse(localStorage.getItem("teachclass_id")),
				}
				Recorreted(data).then(res => {})
				if (this.Rightwrongindex > 0) {
					batch_auxiliary_correction(data1).then(res => {
						this.$parent.parentref();
					})
					queryPlanUserJoinDetail(data2).then(res => {
						if (res.data.result[0]) {
							let data3 = {
								"answerExtendList": [{
									"answerId": this.teach_val.questionOutVo.answerList[val
										.serialAnswer - 1].id,
									"serial": this.teach_val.questionOutVo.answerList[val
										.serialAnswer - 1].serial,
									"correctValue": res.data.result[0],
									"value": res.data.result[0],
								}],
								"id": this.teach_val.id,
								"featureCode": this.teach_val.questionOutVo.featureCode,
								"subjectId": this.teach_val.questionOutVo.subjectId
							}
							addUserExtendAnswerList(data3).then(res => {})
						}
					})
				}
			},
			canvasclick(e) {
				let myCanvas = document.getElementById(this.className)
				this.teach_val = JSON.parse(localStorage.getItem('teach_val'))
				const localXY = {
					x: e.clientX - myCanvas.getBoundingClientRect().left,
					y: e.clientY - myCanvas.getBoundingClientRect().top
				}
				let canvesWidth = this.canvesWidth / 2
				let canvesHeight = this.canvesHeight / 2
				if (canvesWidth - 64 < localXY.x && localXY.x < canvesWidth && canvesHeight - 64 < localXY.y && localXY.y <
					canvesHeight) {
					if (this.Rightwrongindex == 0) {
						this.Rightwrongindex = 1
						if (localStorage.getItem('teach_kongindex')) {
							this.teachercalibrate(this.teach_val.answerAreaList[localStorage.getItem('teach_kongindex')],
								this.teach_val.id)
						} else {
							this.teachercalibrate(this.teach_val.answerAreaList[0], this.teach_val.id)
						}
						this.draw_gou(this.canvesWidth / 2, this.canvesHeight / 2)
					} else if (this.Rightwrongindex == 1) {
						this.Rightwrongindex = 0
						if (localStorage.getItem('teach_kongindex')) {
							this.teachercalibrate(this.teach_val.answerAreaList[localStorage.getItem('teach_kongindex')],
								this.teach_val.id)
						} else {
							this.teachercalibrate(this.teach_val.answerAreaList[0], this.teach_val.id)
						}
						this.draw_cha(this.canvesWidth / 2, this.canvesHeight / 2)
					}
				}
			},
			draw_gou(x, y) {
				this.$nextTick(() => {
					var img = require('../assets/img/Yes.png')
					$("#" + this.className).removeLayer('clear' + x + y)
					$("#" + this.className).addLayer({
						method: "drawImage",
						source: img,
						x: x + 32,
						y: y + 32,
						width: 20,
						height: 20,
						layer: true,
						name: 'clear' + x + y
					}).drawLayers();
				})
				clearInterval(this.timer3);
			},
			//分数变红
			draw_score(x, y, data) {
				$("canvas").removeLayer(x + 'clear' + y)
				$('canvas').addLayer({
					name: x + 'clear' + y,
					method: "drawText",
					fillStyle: 'red',
					strokeStyle: '#25a',
					x: x - 32,
					y: y + 10,
					fontSize: 24,
					fontFamily: 'Verdana, sans-serif',
					text: data == '-1' ? 0 : data
				}).drawLayers()
			},
			draw_wen(x, y, types) {
				this.$nextTick(() => {
					var img = require('../assets/img/wen.png')
					$("#" + this.className).removeLayer('clear' + x + y)
					$("#" + this.className).addLayer({
						method: "drawImage",
						source: img,
						x: x + 32,
						y: y + 32,
						width: 20,
						height: 20,
						layer: true,
						name:'clear' + x + y			
					}).drawLayers();
				})
			},
			//答题区域
			answerarea(x1, x2, y1, y2) {
				$("#" + this.className).drawRect({
					layer: true,
					strokeStyle: '#deebff',
					// strokeStyle: 'red',
					x: (x2 + x1) / 2,
					y: (y1 + y2) / 2,
					width: x2 - x1,
					height: y2 - y1
				});
			},
			draw_bangou(x, y) {
				var img = require('../assets/img/banYes.png')
				$("#" + this.className).removeLayer('clear' + x + y)
				$("#" + this.className).addLayer({
					method: "drawImage",
					source: img,
					x: x + 32,
					y: y + 32,
					width: 24,
					height: 24,
					layer: true,
					name: 'clear' + x + y
				}).drawLayers();
				clearInterval(this.timer3);
			},
			draw_cha(x, y) {
				var img = require('../assets/img/No.png')
				$("#" + this.className).removeLayer('clear' + x + y)
				$("#" + this.className).addLayer({
					method: "drawImage",
					source: img,
					x: x + 32,
					y: y + 32,
					width: 24,
					height: 24,
					layer: true,
					name: 'clear' + x + y
				}).drawLayers();
				clearInterval(this.timer3);
			},
			setimeteach_val() {
				if (JSON.parse(localStorage.getItem('teachplanIdType')) == '课中' && JSON.parse(localStorage.getItem(
						'teachplanIdPlankind')) == '作业' && JSON.parse(localStorage.getItem(
						'teachanswertype')) == '作业本') {
					let canshu = {
						"planId": JSON.parse(localStorage.getItem("teachplan_id")),
						"questionId": JSON.parse(localStorage.getItem("teachquestion_id")),
						userId: this.classItem.id,
						"serial": localStorage.getItem("teach_kongindex") ? JSON.parse(localStorage.getItem(
							"teach_kongindex")) + 1 : 1,
					}
					queryPlanUserJoinRecords(canshu).then(res => {
						if (res.data.result[0]) {
							if (res.data.result[0].auto_result == 1) {
								this.Rightwrongindex = 1
								this.draw_gou(this.canvesWidth / 2, this.canvesHeight / 2)
							} else if (res.data.result[0].auto_result >= 0.5 && res.data.result[0].auto_result <
								1) {
								this.draw_bangou(this.canvesWidth / 2, this.canvesHeight / 2)
								this.Rightwrongindex = 0.5
							} else if (res.data.result[0].auto_result == -1) {} else {
								this.Rightwrongindex = 0
								this.draw_cha(this.canvesWidth / 2, this.canvesHeight / 2)
							}
						}
					})
				}
			},
			getstudentresult() {
				getStudentResult({
					"userID": JSON.parse(localStorage.getItem("teachlogin")).id,
					"student_id": this.classItem.id,
					"plan_id": JSON.parse(localStorage.getItem("teachplan_id")),
					"setId": JSON.parse(localStorage.getItem("teachsetId")),
					"pageId": this.pageIds[this.img_listIndex],
					onlyParent: 1,
					"userToken": JSON.parse(localStorage.getItem("teachlogin")).token,
				}).then(response => {
					this.questionaeralist=response.data.data
					this.corrected_result = JSON.parse(JSON.stringify(response.data.data))
					this.corrected_result.forEach((item, index) => {
						if (item.childrenList) {
							item.childrenList.forEach((items, indexs) => {
								this.corrected_result[index].childrenList[indexs] = this.fn3(items)
							})
						} else {
							this.corrected_result[index] = this.fn3(item)
						}
						this.positionrendering(this.corrected_result[index], "startendgoucha")
					})
				}).catch(res => {
				});
			},
			fn3(tempArr) {
				let result = JSON.parse(JSON.stringify(tempArr))
				result.answerAreaList = [];
				let obj = {};
				for (let i = 0; i < tempArr.answerAreaList.length; i++) {
					if (this.pictureSize == "A3") {
						if (!obj[tempArr.answerAreaList[i].serialAnswer]) {
							result.answerAreaList.push(tempArr.answerAreaList[i]);
							obj[tempArr.answerAreaList[i].serialAnswer] = true;
						}
					} else {
						if (!obj[tempArr.answerAreaList[i].serialAnswer]) {
							result.answerAreaList.push(tempArr.answerAreaList[i]);
							obj[tempArr.answerAreaList[i].serialAnswer] = true;
						}
					}
				}
				return result;
			},
			positionrendering(item, data, canvas_click_x, canvas_click_y) {
				if (Object.prototype.toString.call(item.childrenList) === '[object Array]' && item.childrenList.length >
					0) {
					item.childrenList.forEach(items => {
						if (canvas_click_x && canvas_click_y) {
							this.positionrendering(items, data, canvas_click_x, canvas_click_y)
						} else {
							this.positionrendering(items, data)
						}
					})
				} else {
					//答题区域
					var questionId = item.id
					item.answerAreaList.forEach((answerAreaitem, index) => {
						if (this.pictureSize == "A3") {
							if (!answerAreaitem.stautes) {
								answerAreaitem.stautes = true
							}
						}
						var startX = answerAreaitem.startX * 2.032 / this.imgWidth * this.canvesWidth
						var endX = answerAreaitem.endX * 2.032 / this.imgWidth * this.canvesWidth
						var startY = answerAreaitem.startY * 2.032 / this.imgHeight * this.canvesHeight 
						var endY = answerAreaitem.endY * 2.032 / this.imgHeight * this.canvesHeight 
						// var startX = answerAreaitem.startX * this.Magnification* 2.032
						// var endX = answerAreaitem.endX  * this.Magnification* 2.032
						// var startY = answerAreaitem.startY   * this.Magnification* 2.032
						// var endY = answerAreaitem.endY   * this.Magnification* 2.032
						if (this.pictureSize == "A3" && answerAreaitem.pageNo % 2 == 0) {
							startX = startX + this.canvesWidth  / 2
							endX = endX + this.canvesWidth / 2
						}
						var x = (startX + endX) / 2
						var y = (startY + endY) / 2
						var pigai = 0
						if (data == "startendgoucha") {
							if(this.pictureSize == 'A3'){
								if((this.img_listIndex + 1 == answerAreaitem.pageNo) || (this.img_listIndex + 2 ==answerAreaitem.pageNo)){
									if (answerAreaitem.result == 1) {
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind == '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_gou(x-32, y-32, "source-atop")
									} else if (answerAreaitem.result == 0.5) {
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind== '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_bangou(x-32, y-32, "source-atop")
									} else if (answerAreaitem.result == 0) {
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind == '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_cha(x-32, y-32, "source-atop")
									} else {
										this.draw_wen(x-32, y-32, "source-atop")
									}
								}
							}else{
								if(this.img_listIndex + 1 == answerAreaitem.pageNo){
									if (answerAreaitem.result == 1) {
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind == '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_gou(x - 32, y - 32, "source-atop")
									} else if (answerAreaitem.result == 0.5) {
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind == '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_bangou(x - 32, y - 32, "source-atop")
									} else if (answerAreaitem.result == 0) {
										
										if (JSON.parse(localStorage.getItem("teachQuestionSetInformation")).planKind == '测验') {
											this.draw_score(startX, startY, answerAreaitem.correct_score)
										}
										this.draw_cha(x - 32, y - 32, "source-atop")
									} else {
										this.draw_wen(x - 32, y - 32, "source-atop")
									}
								}
							}
						}
					})
				}
			},
			shangyyiye() {
				if (this.pictureSize == "A3") {
					if (this.img_listIndex >= 1) {
						this.getWebSocketclose()
						$("#" + this.className).removeLayer('myBox' + this.dots_draw1data).drawLayers()
						this.img_listIndex = this.img_listIndex - 2
						localStorage.setItem('teachClassid' + this.classItem.id, JSON.stringify(this.img_listIndex))
						this.init_canvas()
					}
				}else{
					if (this.img_listIndex >= 1) {
						this.getWebSocketclose()
						$("#" + this.className).removeLayer('myBox' + this.dots_draw1data).drawLayers()
						this.img_listIndex = this.img_listIndex - 1
						localStorage.setItem('teachClassid' + this.classItem.id, JSON.stringify(this.img_listIndex))
						this.init_canvas()
					}
				}
			},
			xiayiye() {
				if (this.pictureSize == "A3") {
					if (this.img_listIndex >= 0 && this.img_list.length >= this.img_listIndex + 3) {
						this.getWebSocketclose()
						$("#" + this.className).removeLayer('myBox' + this.dots_draw1data).drawLayers()
						this.img_listIndex = this.img_listIndex + 2
						localStorage.setItem('teachClassid' + this.classItem.id, JSON.stringify(this.img_listIndex))
						this.init_canvas()
					}
				}else{
					if (this.img_listIndex >= 0 && this.img_list.length >= this.img_listIndex + 2) {
						this.getWebSocketclose()
						$("#" + this.className).removeLayer('myBox' + this.dots_draw1data).drawLayers()
						this.img_listIndex = this.img_listIndex + 1
						localStorage.setItem('teachClassid' + this.classItem.id, JSON.stringify(this.img_listIndex))
						this.init_canvas()
					}
				}
			},
			getWebSocketclose() {
				clearInterval(this.timer2)
				clearInterval(this.timer3)
				if (this.ws) {
					this.ws.close()
				}
				if (this.ws1) {
					this.ws1.close()
				}
			},
			getdot() {
				let startPageId = '';
				let beginTime = '';
				if (JSON.parse(localStorage.getItem('teachplanIdPlankind')) == '作业' && JSON.parse(localStorage.getItem(
						'teachplanIdType')) == '课中' && JSON.parse(localStorage.getItem(
						'teachanswertype')) == '作业本') {
					startPageId = 0;
					let canshu = {
						"planId": JSON.parse(localStorage.getItem("teachplan_id")),
						"questionId": JSON.parse(localStorage.getItem("teachquestion_id")),
						startFlag: false,
						referenceType: 'class',
						referenceId: localStorage.getItem("teachusertype") == 'remote_teacher' ? 0 : JSON.parse(
							localStorage.getItem("teachclass_id")),
						"serial": localStorage.getItem("teach_kongindex") ? JSON.parse(localStorage.getItem(
							"teach_kongindex")) + 1 : 1,
					}
					queryQuestionTeachClass(canshu).then(res => {
						if (res.data.result) {
							this.getdotsv3dataval = res.data.result
							this.getdotsv3data(res.data.result)
						}
					})
				} else {
					startPageId = this.pageIds[this.img_listIndex]
					beginTime = new Date(JSON.parse(localStorage.getItem("teachstart_time"))).getTime();
					if (this.pictureSize == "A3") {
						let data2 = {
							"data": {
								"beginTime": new Date(JSON.parse(localStorage.getItem("teachstart_time"))).getTime(),
								"endTime": new Date().getTime(),
								"endX": 104,
								"endY": 147.16141732283464,
								"startX": 0,
								"startY": 0,
								"startPageId": this.pageIds[this.img_listIndex+1],
								"endPageId": this.pageIds[this.img_listIndex+1],
								"penMac": this.classItem.mac
							}
						}
						getdotsv3(data2).then(response => {
							this.penMac = response.data.penMac
							this.dots_data_er=response.data.Stroke
							this.dots_draw1(this.dots_data_er, '5','二');
						});
					}
					let data2 = {
						"data": {
							"beginTime": new Date(JSON.parse(localStorage.getItem("teachstart_time"))).getTime(),
							"endTime": new Date().getTime(),
							"endX": 104,
							"endY": 147.16141732283464,
							"startX": 0,
							"startY": 0,
							"startPageId": startPageId,
							"endPageId": startPageId,
							"penMac": this.classItem.mac
						}
					}
					getdotsv3(data2).then(response => {
						this.penMac = response.data.penMac
						this.dots_data = response.data.Stroke
						this.dots_draw1(this.dots_data, '5');
					});
				}
			},
			getdotsv3data(val, canshu) {
				let data2 = {
					"data": {
						"beginTime": val.beginTime ? val.beginTime : new Date().getTime(),
						"endTime": canshu ? new Date().getTime() : val.endTime ? val.endTime : new Date().getTime(),
						"endX": 104,
						"endY": 147.16141732283464,
						"startX": 0,
						"startY": 0,
						"startPageId": 0,
						"endPageId": 0,
						"penMac": this.classItem.mac
					}
				}
				getdotsv3(data2).then(response => {
					this.dots_data = response.data.Stroke
					if (!canshu) {
						this.penMac = response.data.penMac
						if (response.data.Stroke.length > 0) {
							this.setimeteach_val()
							this.dots_draw1(this.dots_data, '5');
						}
					} else {
						this.dots_draw2(response.data.Stroke, '5')
					}
				});
			},
			getWebSocket() {
				this.getdot()
				if (this.ws) {
					this.ws.close()
				}
				if (this.ws1) {
					this.ws1.close()
				}
				let params1 = "KS0012" + this.classItem.mac
				let studentId = this.classItem.id
				let planId = JSON.parse(localStorage.getItem("teachplan_id"))
				let params2 = "KS0014" + planId + ":" + studentId
				const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket'
				WebSocketFunc(this.ws, params1, url, (data) => {
					data = JSON.parse(data)
					if (data.flag != '9') {
						data.result = JSON.parse(data.result)
						this.dots_draw(data.result, '5')
					}
				}, (ws) => {
					this.ws = ws
				}, (timer) => {
					this.timer2 = timer
				})
				WebSocketFunc(this.ws1, params2, url, (data) => {
					data = JSON.parse(data)
					if (data.flag != '9') {
						data.result = JSON.parse(data.result)
						this.dots_draw(data.result, '5')
					}
					if (JSON.parse(localStorage.getItem('teachplanIdPlankind')) == '作业' && JSON.parse(localStorage
							.getItem(
								'teachplanIdType')) == '课中' && JSON.parse(localStorage.getItem(
							'teachanswertype')) == '作业本') {
						if (data.result.result == 1) {
							this.draw_gou(this.canvesWidth / 2, this.canvesHeight / 2)
						}
						if (data.result.result < 1 && data.result.result >= 0.5) {
							this.draw_bangou(this.canvesWidth / 2, this.canvesHeight / 2)
						} else {
							this.draw_cha(this.canvesWidth / 2, this.canvesHeight / 2)
						}
					} else {

					}
				}, (ws) => {
					this.ws1 = ws
				}, (timer) => {
					this.timer3 = timer
				})
			},
			Repaint() {
				this.remyBox = 0
				this.myBox = 0
				this.dots_data=this.dots_data.concat(this.dots_data_er)
				this.dots_draw2(this.dots_data, '5')
			},
			dots_draw2(data, n,type) {
				$("#" + this.className).removeLayer('myBox' + (this.dots_draw1data-1)).drawLayers()
				$("#" + this.className).removeLayer('myBox' + this.dots_draw1data).drawLayers()
				$("#" + this.className).addLayer({
					type: 'function',
					name: 'remyBox',
					fn: () => {
						data.forEach((datasitem, datasindex) => {
							let that = this;
							if (!this.remyBox) {
								(function(j) {
									setTimeout(() => {
										that.cxt.beginPath();
										that.cxt.lineWidth=2
										datasitem.x.forEach((datasitemitem,
											datasitemindex) => {
												if (datasitemindex == 0) {
													if(datasitem.page_id[datasitemindex] == that.pageIds[that.img_listIndex+1]){
														that.cxt.moveTo(datasitemitem * n /that.canvesn+that.canvesWidth /2, datasitem.y[datasitemindex] * n /that.canvesn);
													}else{
														that.cxt.moveTo(datasitemitem * n /that.canvesn, datasitem.y[datasitemindex] * n /that.canvesn);
													}
												} else {
													if(datasitem.page_id[datasitemindex] == that.pageIds[that.img_listIndex+1]){
														that.cxt.lineTo(datasitemitem * n /that.canvesn+that.canvesWidth /2, datasitem.y[datasitemindex] * n /that.canvesn);
													}else{
														that.cxt.lineTo(datasitemitem * n /that.canvesn, datasitem.y[datasitemindex] * n /that.canvesn);
													}
												}
											})
										that.cxt.stroke();
										that.remyBox = 1
									}, 10 * j)
								})(datasindex)
							} else {
								if (!this.myBox) {
									$("#" + this.className).removeLayer('remyBox').drawLayers()
									this.dots_draw1(this.dots_data, '5')
									that.myBox = 1
								}
							}
						})
					}
				}).drawLayers()
			},
			dots_draw1(data, n) {
				this.dots_draw1data += 1
				$("#" + this.className).draw({
					layer: true,
					name: 'myBox' + this.dots_draw1data,
					fn: () => {
						data.forEach((datasitem, datasindex) => {
							let that = this;
							this.cxt.beginPath();
							datasitem.x.forEach((datasitemitem, datasitemindex) => {
								if (datasitemindex == 0) {
									if(datasitem.page_id[datasitemindex] == this.pageIds[this.img_listIndex+1]){
										this.cxt.moveTo(datasitemitem * n / this.canvesn+this.canvesWidth /2, datasitem.y[datasitemindex] * n / this.canvesn);
									}else{
										this.cxt.moveTo(datasitemitem * n / this.canvesn, datasitem.y[datasitemindex] * n / this.canvesn);
									}
								} else {
									if(datasitem.page_id[datasitemindex] == this.pageIds[this.img_listIndex+1]){
										this.cxt.lineTo(datasitemitem * n / this.canvesn+this.canvesWidth /2, datasitem.y[datasitemindex] * n / this.canvesn);
									}else{
										this.cxt.lineTo(datasitemitem * n / this.canvesn, datasitem.y[datasitemindex] * n / this.canvesn);
									}
								}
							})
							this.cxt.stroke();
						})
					}
				}).drawLayers()
			},
			dots_draw(data, n) {
				this.dots_drawindex = this.dots_drawindex + 1
				$("#" + this.className).draw({
					layer: true,
					fn: () => {
						this.cxt.beginPath();
						data.x.forEach((datasitemitem, datasitemindex) => {
							if (datasitemindex == 0) {
								this.cxt.moveTo(datasitemitem * n / this.canvesn, data.y[datasitemindex] * n / this.canvesn);
							} else {
								this.cxt.lineTo(datasitemitem * n / this.canvesn, data.y[
									datasitemindex] * n / this.canvesn);
							}
						})
						this.cxt.stroke();
					}
				})
			},
			init_canvas() {
				this.img_listIndex = localStorage.getItem('teachClassid' + this.classItem.id) ? JSON.parse(localStorage
					.getItem('teachClassid' + this.classItem.id)) : 0
				let img_src = this.img_list[this.img_listIndex]
				var c = document.getElementById(this.$props.className);
				this.cxt = c.getContext("2d");
				this.canvesn = 1
				if (JSON.parse(localStorage.getItem('teachplanIdPlankind')) == '作业' && JSON.parse(localStorage.getItem(
						'teachplanIdType')) == '课中' && JSON.parse(localStorage.getItem(
						'teachanswertype')) == '作业本') {
					var img1 = new Image();
					img1.src = 'https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/notebook/notebook2.png'
					$("#" + this.className).drawImage({
						source: img1.src,
						layer: true,
						x: 0,
						y: 0,
						width: this.canvesWidth,
						height: this.canvesHeight,
						fromCenter: false,
					}).drawLayers();
					this.getWebSocket()
				} else {
					var img1 = new Image();
					img1.src = img_src
					img1.onload = () => {
						if(img1.width>2100){
							this.pictureSize='A3'
						}else{
							this.pictureSize='A4'
						}
						this.imgWidth=img1.width/10
						this.imgHeight=img1.height/10
						this.canvesWidth = Math.floor(img1.width / 2.032 / 2 * 10) / 10;
						if (this.canvesWidth != '516.7') {
							this.Magnification = this.canvesWidth/img1.width *10
							this.canvesn = this.canvesWidth / 516.7
							this.canvesWidth = 516.7
						} else {
							this.Magnification = this.canvesWidth/img1.width *10
							this.canvesn = 1
						}
						this.canvesHeight = img1.height / img1.width * this.canvesWidth
						$("#" + this.className).drawImage({
							source: img1.src,
							layer: true,
							x: 0,
							y: 0,
							width: this.canvesWidth,
							height: this.canvesHeight,
							fromCenter: false,
						}).drawLayers();
						this.getstudentresult()
						this.getWebSocket()
					}
				}
			},
		}
	}
</script>

<style lang="scss">
	.className {
		.className_con {
			border: 2px solid #D8DEEA;
			background: #fff;

			.className_con_bott {
				display: flex;
				border-top: 1px solid #D8DEEA;
				padding: 10px 0;
				justify-content: space-around;

				.className_con_bott_con {
					display: flex;
					align-items: center;

					.className_con_bott_con_span {
						width: 24px;
						height: 24px;
						margin: 0 7px;
						display: inline-block;
						border: 2px solid #D8DEEA;
						border-radius: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.className_con_bott_right {
					color: #8E9BB1;
				}
			}
		}
	}
</style>
