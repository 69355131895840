<template>
  <div class="hello">
    <div
      :id="myCharts"
      :style="{
        display: data3 && data1 && data3 != '' && data1 != '' ? '' : 'none',
      }"
    ></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
//按需引入echarts
var echarts = require('echarts');
require('echarts/lib/chart/bar');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
import { getChoiceResultByQuestionId } from '../../api/base.js';
export default {
  name: 'HelloWorld',
  props: ['myCharts'],
  data() {
    return {
      option: {}, //柱状图配置对象
      yData: '',
      refreshEchart: false,
      legend: null,
      data3: '',
      data2: '',
      data1: '',
      class_id: JSON.parse(localStorage.getItem('teachclass_id')),
      mychartwidth: '300',
      mychartheight: '300',
      echartsshow: '',
      student_list: {},
      only_show_student_list: [],
      student_list_ALL: {},
      student_names: [],
      rightColor: 'rgba(112,245,139,0.4)',
      halfRightColor: 'rgba(250,250,107,0.4)',
      wrongColor: 'rgba(255,102,102,0.4)',
      defaultColor: 'rgba(92,123,217,0.4)',
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    //放大
    sonchartenlarge(data) {
      //data 是true  放大缩小  要刷新echarts  echartsshow是取反操作
      this.echartsshow = data ? !this.echartsshow : this.echartsshow;
      if (this.echartsshow) {
        this.mychartwidth = '700';
        this.mychartheight = '700';
        this.rightColor = 'rgb(112,245,139)';
        this.halfRightColor = 'rgb(250,250,107)';
        this.wrongColor = 'rgb(255,102,102)';
        this.defaultColor = 'rgb(92,123,217)';
      } else {
        this.mychartwidth = '300';
        this.mychartheight = '300';
        this.rightColor = 'rgba(112,245,139,0.4)';
        this.halfRightColor = 'rgba(250,250,107,0.4)';
        this.wrongColor = 'rgba(255,102,102,0.4)';
        this.defaultColor = 'rgba(92,123,217,0.4)';
      }
      this.getChoice();
    },

    getChoice() {
      var res;
      console.log('refreshEchart', this.refreshEchart);
      let that = this;
      let RightStudent = [];
      let halfRightStudent = [];
      let wrongStudent = [];
      let uncorrectStudent = [];
      let data = {
        questionId: JSON.parse(localStorage.getItem('teachquestion_id')),
        class_id: JSON.parse(localStorage.getItem('teachclass_id')), //要给的1-2
        plan_id: JSON.parse(localStorage.getItem('teachplan_id')),
        featureCode: JSON.parse(localStorage.getItem('teach_featureCode')),
      };
      getChoiceResultByQuestionId(data).then((res) => {
        console.log('res', res);
        // console.log(res);
        // if (res.data.infor.length == 0||res.data.students_list.length ==0 ||res.data.serial.length) {
        //   return
        // }
        let canshu1, canshu2;
        if (
          JSON.parse(localStorage.getItem('teach_featureCode')) == '100001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '200001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '300001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '1300001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '1400001'
        ) {
          // if(!this.refreshEchart){
          //            res =
          //           {
          //             data: {
          //               serial: [
          //                 1
          //               ],
          //               infor: [
          //                 {
          //                   result: {
          //                     content_list: [
          //                       "A",
          //                       "B"
          //                     ],
          //                     number_list: [
          //                       20,
          //                       50
          //                     ],
          //                     students_list: [
          //                       [
          //                         "483FDA08E15E",
          //                         "zdx",
          //                         'zdx1',
          //                         'zdx2'
          //                       ],
          //                       [
          //                         "483FDA08E133",
          //                         'zdx7',
          //                         'zdx8',
          //                         'zdx9',
          //                       ]
          //                     ]
          //                   },
          //                   resultRight: [
          //                     "A"
          //                   ],
          //                   resultHalfRight: [],
          //                   resultWrong: [
          //                     "B"
          //                   ]
          //                 }
          //               ]
          //             }
          //           }
          //         }

          // if (this.refreshEchart) {
          // 如果echarts 要刷新的话
          that.student_list = {
            halfRightStudent: [],
            RightStudent: [],
            uncorrectStudent: [],
            wrongStudent: [],
          };
          //1.判断是否为填空题
          //2.写出x轴
          that.data1 = res.data.serial;
          //3.写出y轴
          // // //4.写出legend 右上标
          let yData = [];
          that.data3 = [];
          that.legend = res.data.infor[0].result.content_list;
          //5. 有多少个y轴 （A,B）两个选项
          that.legend.map((item, index) => {
            (yData = {
              type: 'bar', //形状为柱状图
              data: [],
              // name: this.legend[index], // legend属性
              label: {
                // 柱状图上方文本标签，默认展示数值信息
                formatter: `${item}: {c}`,
                show: true,
                position: 'top',
              },
              itemStyle: {
                normal: {
                  color: function(params) {
                    return res.data.infor[
                      params.dataIndex
                    ].resultRight.findIndex(
                      (items) => items == that.legend[index]
                    ) != -1
                      ? `${that.rightColor}`
                      : res.data.infor[
                          params.dataIndex
                        ].resultHalfRight.findIndex(
                          (items) => items == that.legend[index]
                        ) != -1
                      ? `${that.halfRightColor}`
                      : `${that.wrongColor}`;
                  },
                  // color: data.resultRight.findIndex(items => items == that.legend[index]) != -1 ? 'green' : data.resultHalfRight.findIndex(items => items == that.legend[index]) != -1 ? 'yellow' : 'red'
                },
              },
            }),
              that.data3.push(yData);
          });
          //  将每个空的Y轴的数量给data3      res.data.infor :所有空的信息数组  item：每个空的信息
          res.data.infor.forEach((item) =>
            item.result.number_list.forEach(
              (number_listItem, number_listIndex) => {
                //遍历 A B 两个选项(遍历每个空的柱状图)
                that.data3.forEach((data3Item, data3Index) => {
                  //将number_listIndex的索引和data3（每个空）的索引对上  ，把数量赋值给data3
                  if (data3Index == number_listIndex) {
                    that.data3[number_listIndex].data.push(number_listItem);
                    // that.data3[number_listIndex].data = number_listItem
                  }
                });
              }
            )
          );
          res.data.infor.forEach((item, index) => {
            //   ['A', 'B', 'C', 'D'] item：每个空的信息
            item.result.content_list.forEach(
              (content_listItem, content_listIndex) => {
                // ['A']    遍历正确答案的数组
                let indexrightChild = item.resultRight.findIndex(
                  (resultRightItem, resultRightIndex) => {
                    //拿到正确答案在所有答案数组里的索引
                    return resultRightItem == content_listItem;
                  }
                );
                // 正确答案在所有答案数组里(这个判断感觉不是很必要)
                if (indexrightChild != -1) {
                  //将正确答案的索引对应着正确学生的索引，将正确学生放入student_list中
                  that.student_list.RightStudent.push(
                    ...item.result.students_list[content_listIndex]
                  );
                }
              }
            );
            //拿到半对学生的名单并给student_list
            item.result.content_list.forEach(
              (content_listItem, content_listIndex) => {
                let indexHalfRightChild = item.resultHalfRight.findIndex(
                  (resultHalfRightItem, resultHalfRightIndex) => {
                    return resultHalfRightItem == content_listItem;
                  }
                );
                if (indexHalfRightChild != -1) {
                  that.student_list.halfRightStudent.push(
                    ...item.result.students_list[content_listIndex]
                  );
                }
              }
            );

            //拿到错误学生的名单并给student_list
            item.result.content_list.forEach(
              (content_listItem, content_listIndex) => {
                let indexWrongChild = item.resultWrong.findIndex(
                  (resultWrongItem, resultWrongIndex) => {
                    return resultWrongItem == content_listItem;
                  }
                );
                if (indexWrongChild != -1) {
                  that.student_list.wrongStudent.push(
                    ...item.result.students_list[content_listIndex]
                  );
                }
              }
            );
          });
          console.log(that.student_list);

          // }
          // else {
          //   // echarts不刷新  （不切换提的情况）
          //   //0.清空 要拿的学生对错列表
          //   that.student_list = {
          //     halfRightStudent: [],
          //     RightStudent: [],
          //     uncorrectStudent: [],
          //     wrongStudent: []
          //   };
          //   //1. 更换y轴
          //   //清空Y轴的值
          //   that.data3.forEach((data3Item, data3Index) => {
          //     data3Item.data =
          //       })
          //   //  将每个空的Y轴的数量给data3      res.data.infor :所有空的信息数组  item：每个空的信息
          //   res.data.infor.forEach((item) =>
          //     item.result.number_list.forEach((number_listItem, number_listIndex) => {
          //       //遍历 A B 两个选项(遍历每个空的柱状图)
          //       that.data3.forEach((data3Item, data3Index) => {
          //         //将number_listIndex的索引和data3（每个空）的索引对上  ，把数量赋值给data3
          //         if (data3Index == number_listIndex) {
          //           // that.data3[number_listIndex].data.push(number_listItem)
          //           //就是重新赋值y轴
          //           that.data3[number_listIndex].data = number_listItem
          //         }
          //       })
          //     })
          //   );
          //   // 2.更换放入对错的学生的名单
          //   res.data.infor.forEach((item, index) => {
          //     //   ['A', 'B', 'C', 'D'] item：每个空的信息
          //     item.result.content_list.forEach((content_listItem, content_listIndex) => {
          //       // ['A']    遍历正确答案的数组
          //       let indexrightChild = item.resultRight.findIndex((resultRightItem, resultRightIndex) => {
          //         //拿到正确答案在所有答案数组里的索引
          //         return resultRightItem == content_listItem
          //       })
          //       // 正确答案在所有答案数组里(这个判断感觉不是很必要)
          //       if (indexrightChild != -1) {
          //         //将正确答案的索引对应着正确学生的索引，将正确学生放入student_list中
          //         that.student_list.RightStudent.push(...item.result.students_list[content_listIndex])
          //       }
          //     })
          //     //拿到半对学生的名单并给student_list
          //     item.result.content_list.forEach((content_listItem, content_listIndex) => {

          //       let indexHalfRightChild = item.resultHalfRight.findIndex((resultHalfRightItem, resultHalfRightIndex) => {

          //         return resultHalfRightItem == content_listItem
          //       })
          //       if (indexHalfRightChild != -1) {
          //         that.student_list.halfRightStudent.push(...item.result.students_list[content_listIndex])
          //       }
          //     })

          //     //拿到错误学生的名单并给student_list
          //     item.result.content_list.forEach((content_listItem, content_listIndex) => {
          //       let indexWrongChild = item.resultWrong.findIndex((resultWrongItem, resultWrongIndex) => {

          //         return resultWrongItem == content_listItem
          //       })
          //       if (indexWrongChild != -1) {
          //         that.student_list.wrongStudent.push(...item.result.students_list[content_listIndex])
          //       }
          //     })
          //   })

          // }
          // // //5.显示学生列表
          that.student_names = res.data.infor;
          let allData = [that.student_list, res.data.serial.length];
          that.getStudentList(allData); //放进vuex学生笔迹判断学生对错问题
        } else {
          if (
            res.data.number_list &&
            res.data.number_list.length > 0 &&
            res.data.content_list &&
            res.data.content_list.length > 0
          ) {
            //作图题

            //             if(!this.refreshEchart){
            //         res = {
            //           data:{
            //     "content_list": [
            //         "已提交人数"
            //     ],
            //     "number_list": [
            //         3
            //     ],
            //     "students_list": [
            //         "483FDA08E133",
            //         "483FDA08E134",
            //         "483FDA08E135"
            //     ]
            // }
            //         }
            //       }
            this.data1 = res.data.content_list;
            this.data2 = res.data.number_list;
            that.only_show_student_list = res.data.students_list;
            that.getStudentList(res.data);
            this.data3 = [
              {
                type: 'bar',
                data: this.data2,
                label: {
                  formatter: ` {c}`,
                  show: true,
                  position: 'top',
                },
                itemStyle: {
                  color: this.defaultColor,
                },
              },
            ];
          } else if (res.data.serial && res.data.serial.length > 0) {
            //  if(!this.refreshEchart){
            //           res = {data:{
            //             "serial": [
            //               1
            //             ],
            //             "infor": [
            //               {
            //                 "right_student": [
            //                   "483FDA08E133",
            //                   'zdx1',
            //                   'zdx2',
            //                   'zdx3',
            //                   'zdx4'
            //                 ],
            //                 "half_right_student": [],
            //                 "wrong_student": [],
            //                 "uncorrect_student": []
            //               }
            //             ]
            //           }
            //         }
            //         }

            //填空题
            that.only_show_student_list.length = 0;
            this.data1 = res.data.serial;
            let filterArray = this.filterArray(res.data.infor);
            let data = filterArray.reCanshu;
            //这里替换了全对学生
            let legendIndex = filterArray.canshu.findIndex(
              (item) => item == '全对学生'
            );
            filterArray.canshu.splice(legendIndex, 1, '正确学生');
            //这里替换了全对学生
            this.legend = filterArray.canshu;
            that.student_list = {
              halfRightStudent: [],
              RightStudent: [],
              uncorrectStudent: [],
              wrongStudent: [],
            };
            that.student_list = res.data.infor;
            res.data.infor.forEach((item) => {
              data.forEach((data_item) => {
                if (data_item.name == '全对学生') {
                  data_item.data.push(item.right_student.length);
                  RightStudent.push(...item.right_student);
                } else if (data_item.name == '半对学生') {
                  data_item.data.push(item.half_right_student.length);
                  halfRightStudent.push(...item.half_right_student);
                } else if (data_item.name == '未批改学生') {
                  data_item.data.push(item.uncorrect_student.length);
                  uncorrectStudent.push(...item.uncorrect_student);
                } else if (data_item.name == '错误学生') {
                  data_item.data.push(item.wrong_student.length);
                  wrongStudent.push(...item.wrong_student);
                }
              });
            });
            //去重
            // function noRepeat(arr) {
            // 	var newArr = [...new Set(arr)]; //利用了Set结构不能接收重复数据的特点
            // 	return newArr
            // }
            // RightStudent = noRepeat(RightStudent);
            // halfRightStudent = noRepeat(halfRightStudent);
            // wrongStudent = noRepeat(wrongStudent);
            // uncorrectStudent = noRepeat(uncorrectStudent);
            //算百分比
            that.student_list_ALL = {
              RightStudent,
              halfRightStudent,
              wrongStudent,
              uncorrectStudent,
            };
            //传所有对错学生的姓名  ，  题目数量
            let allData = [that.student_list_ALL, res.data.infor.length];
            that.getStudentList(allData);
            data.forEach((itemData, indexData, arrData) => {
              //这里替换了全对学生
              if (itemData.name == '全对学生') {
                arrData[indexData].name = '正确学生';
              }
              //
              arrData[indexData].label = {
                // 柱状图上方文本标签，默认展示数值信息
                formatter: ` {c}`,
                show: true,
                position: 'top',
              };
            });
            this.data3 = data;
            console.log(data);
          } else {
            if (
              this.$parent.que[this.$parent.selectmintopicindex].childrenList
            ) {
              if (
                this.$parent.childrenListindex ||
                this.$parent.childrenListindex == 0
              ) {
                canshu1 = this.$parent.que[this.$parent.selectmintopicindex]
                  .childrenList[this.$parent.childrenListindex].right_students
                  .length;
                canshu2 = this.$parent.que[this.$parent.selectmintopicindex]
                  .childrenList[this.$parent.childrenListindex].wrong_students
                  .length;
                this.showTrue(canshu1, canshu2);
              } else {
                this.data1 = null;
              }
            } else {
              //计算题
              canshu1 = this.$parent.que[this.$parent.selectmintopicindex]
                .right_students.length;
              canshu2 = this.$parent.que[this.$parent.selectmintopicindex]
                .wrong_students.length;
              console.log(
                '268464:',
                that.$store.state.StudentList[0],
                that.$store.state.StudentList[1]
              );
              this.showTrue(canshu1, canshu2);
            }
            this.data3 = [
              {
                type: 'bar',
                data: this.data2,
              },
            ];
          }
        }
        if (this.data1) {
          this.drawLine();
        }

        // this.drawLine();
      });
    },
    filterArray(data) {
      // console.log(data);
      let reData = {
        half_right_student: [],
        right_student: [],
        uncorrect_student: [],
        wrong_student: [],
      };
      let canshu = [];
      data.forEach((item) => {
        reData.half_right_student = reData.half_right_student.concat(
          item.half_right_student
        );
        reData.right_student = reData.right_student.concat(item.right_student);
        reData.uncorrect_student = reData.uncorrect_student.concat(
          item.uncorrect_student
        );
        reData.wrong_student = reData.wrong_student.concat(item.wrong_student);
      });
      if (
        !(reData.half_right_student && reData.half_right_student.length > 0)
      ) {
        delete reData.half_right_student;
      } else {
        canshu.push('半对学生');
      }
      if (!(reData.right_student && reData.right_student.length > 0)) {
        delete reData.right_student;
      } else {
        canshu.push('全对学生');
      }
      if (!(reData.uncorrect_student && reData.uncorrect_student.length > 0)) {
        delete reData.uncorrect_student;
      } else {
        canshu.push('未批改学生');
      }
      if (!(reData.wrong_student && reData.wrong_student.length > 0)) {
        delete reData.wrong_student;
      } else {
        canshu.push('错误学生');
      }
      let reCanshu = [];
      canshu.forEach((item) => {
        let shuju = {
          itemStyle: {
            color:
              item == '全对学生'
                ? `${this.rightColor}`
                : item == '错误学生'
                ? `${this.wrongColor}`
                : item == '半对学生'
                ? `${this.halfRightColor}`
                : `${this.defaultColor}`,
          },
          name: item,
          type: 'bar',
          data: [],
        };
        reCanshu.push(shuju);
      });
      return {
        reCanshu,
        canshu,
      };
    },
    // 是否渲染
    showTrue(canshu1, canshu2) {
      if (canshu1 == 0 || canshu2 == 0) {
        if (canshu1 == 0) {
          this.data1 = ['错误'];
          this.data2 = [canshu2];
        }
        if (canshu2 == 0) {
          this.data1 = ['正确'];
          this.data2 = [canshu1];
        }
        if (canshu2 == 0 && canshu1 == 0) {
          this.data1 = null;
          this.data2 = null;
        }
      } else {
        this.data1 = ['正确', '错误'];
        this.data2 = [canshu1, canshu2];
      }
    },
    formatDate: function(value, type) {
      //调用时间戳为日期显示
      let date = new Date(value);
      let y = date.getFullYear(); //获取年份
      let m = date.getMonth() + 1; //获取月份
      m = m < 10 ? '0' + m : m; //月份不满10天显示前加0
      let d = date.getDate(); //获取日期
      d = d < 10 ? '0' + d : d; //日期不满10天显示前加0
      //也可以获取更精准时间
      let h = date.getHours(); //小时
      h = h < 10 ? '0' + h : h; //月份不满10天显示前加0
      let mi = date.getMinutes(); //分
      mi = mi < 10 ? '0' + mi : mi; //月份不满10天显示前加0
      let s = date.getSeconds(); //秒
      s = s < 10 ? '0' + s : s; //月份不满10天显示前加0
      if (type) {
        return h + ':' + mi + ':' + s;
      } else {
        return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
      }
    },
    //shi
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let that = this;
      if (this.refreshEchart) {
        let dom = document.getElementById(this.myCharts);
        let mychartwidth = this.mychartwidth;
        let mychartheight = this.mychartheight;

        if (dom) {
          dom.innerHTML =
            '<div class="chart" id="main" style="width:' +
            mychartwidth +
            'px;height:' +
            mychartheight +
            'px"></div>';
          var myChart = echarts.init(document.getElementById('main'));
          // 阻止冒泡
          myChart.on('legendselectchanged', function(params) {
            event.stopPropagation();
            event.cancelBubble = true;
          });
          // console.log('1111efsfs', myChart.setOption);
          // 绘制图表
          this.$nextTick(() => {
            (that.option = {
              title: {
                text: '答题人数',
              },
              legend: {
                right: 'right',
                data: this.legend,
              },
              tooltip: {
                trigger:
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '100001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '200001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '300001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '1300001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '1400001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '400001' ||
                  JSON.parse(localStorage.getItem('teach_featureCode')) ==
                    '500001'
                    ? 'item'
                    : 'axis',
                formatter: function(params, ticket, callback) {
                  let res;
                  if (
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '100001' ||
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '200001' ||
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '300001' ||
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '1300001' ||
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '1400001'
                  ) {
                    //params.seriesIndex代表第一个空第几个柱子
                    return (
                      `<div>第${params.name}空</div>` +
                      `${
                        that.student_names[0].result.content_list[
                          params.seriesIndex
                        ]
                      }: ` +
                      params.data +
                      '人' + //数据第几个空
                      '<br/>答题人：<br/>' +
                      that.student_names[
                        Number(params.name) - 1
                      ].result.students_list[params.seriesIndex].map(
                        (item, index) => {
                          return (index + 1) % 5 == 0
                            ? `<br/><span>${item}<span>`
                            : `<span>${item}<span>`;
                        }
                      )
                    );
                  } else if (
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '400001' ||
                    JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '500001'
                  ) {
                    return (
                      '<div>' +
                      `<div>第${params.name}空</div>` +
                      `${params.seriesName}: ` +
                      params.data +
                      '人' +
                      '<br/>答题人：<br/>' +
                      (params.seriesName === '半对学生'
                        ? that.student_list[
                            params.dataIndex
                          ].half_right_student.map(
                            (itemChildren, indexChildren) => {
                              return (indexChildren + 1) % 5 == 0
                                ? `<br/><span>${itemChildren}<span>`
                                : `<span>${itemChildren}<span>`;
                            }
                          )
                        : params.seriesName === '正确学生'
                        ? that.student_list[params.dataIndex].right_student.map(
                            (itemChildren, indexChildren) => {
                              return (indexChildren + 1) % 5 == 0
                                ? `<br/><span>${itemChildren}<span>`
                                : `<span>${itemChildren}<span>`;
                            }
                          )
                        : params.seriesName === '错误学生'
                        ? that.student_list[params.dataIndex].wrong_student.map(
                            (itemChildren, indexChildren) => {
                              return (indexChildren + 1) % 5 == 0
                                ? `<br/><span>${itemChildren}<span>`
                                : `<span>${itemChildren}<span>`;
                            }
                          )
                        : '无') +
                      '</div>'
                    );
                  } else {
                    res =
                      '第' +
                      params[0].value +
                      '空' +
                      '<br/>' +
                      that.only_show_student_list.map((item, index) => {
                        return (index + 1) % 5 == 0
                          ? `<br/><span>${item}<span>`
                          : `<span>${item}<span>`;
                      });
                  }
                  return res;
                },
              },
              series: this.data3,
              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    interval: 0, //代表显示所有x轴标签显示
                  },
                  data: this.data1.map((item) => {
                    if (
                      JSON.parse(localStorage.getItem('teach_featureCode')) ==
                      '1400001'
                    ) {
                      return that.formatDate(item, true);
                    } else {
                      return item;
                    }
                  }),
                },
              ],
              yAxis: {
                minInterval: 1,
              },
            }),
              myChart.setOption(that.option, true);
          });
        }
      } else {
        //拿到图表
        var myChart = echarts.init(document.getElementById('main'));
        // 阻止冒泡
        myChart.on('legendselectchanged', function(params) {
          event.stopPropagation();
          event.cancelBubble = true;
        });
        that.option.tooltip.trigger =
          JSON.parse(localStorage.getItem('teach_featureCode')) == '100001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '200001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '300001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '1300001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '1400001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '400001' ||
          JSON.parse(localStorage.getItem('teach_featureCode')) == '500001'
            ? 'item'
            : 'axis';

        // that.option.tooltip.formatter(params, ticket, callback);
        that.option.series = this.data3;
        myChart.setOption(that.option, true);
      }
    },
    ...mapMutations(['getStudentList']),
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.hello {
  position: absolute;
  right: 30px;
  background: rgba(0, 0, 0, 0.01);
}
</style>
